import { combineReducers } from 'redux';

import tabs from './tabs';
import searchSieve from './searchSieve';
import partnership from './partnerShip';
import list from './list';
import enterprisesBase from './enterprisesBase';
import identity from './Identity'
import selectLevel from './selectLevel';
import enableBackfill from './enableBackfill';

export default combineReducers({
    tabs,
    searchSieve,
    partnership,
    list,
    enterprisesBase,
    identity,
    selectLevel,
    enableBackfill
})