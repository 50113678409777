
export const tabsConfig = [
    {
        title: '招标订阅',
        sub: '1'
    }, {
        title: '拟在建订阅',
        sub: '2'

    }
]

export const manageConfig = {
    12: '招标订阅',
    3: '拟在建订阅'
}

export const typeSubscribeConfig = {
    12: '标讯',
    3: '拟在建项目'
}

export const typeConfig = {
    SING: 'singleDom',
    MUTIPE: 'multipleDom',
    MACHINE: 'machineDOM'
}


export const wordsConfig = {
    KEYWORSDS: 'keywords',
    DISLODGE:'dislodge'
}

export const wordsConfigStautes = {
    [wordsConfig.KEYWORSDS]: '关键词',
    [wordsConfig.DISLODGE]: '排除词'
}

// redux  路径标识
export const modalConfig = {
    TYPE: 'type',
    NEWS: 'news',
    INDUSTRY:'industry',
    REGION: 'region',
    SEARCHSCOP: 'searchScop', // 搜索范围
    SEARCHMODEL: 'searchModel',


}

// s搜索范围
export const rangConfig = {
    0: '全部',
    1: '标题',
    2: '正文'
}
// 搜索模式
export const searchModeConfig = {
    1: '精确搜索' ,
    0: '模糊搜索'
}

// 收藏配置
export const collectionConfig = {
    SUBSCRIBE:'SUBSCRIBE',
    MONITOR: 'MONITOR'
}


// 权限套餐
export const powerConfig = {
    TRIAL: '10000', //免费版
    STANDARD: '20000',// 标准
    SENIOR: '30000', //高级
    PROFEIONAL: '40000' // 专业
}

export const titleConfig = {
    [modalConfig.NEWS]: {
        name: '全部类型',
        oneLevel: 0

    },
    [modalConfig.REGION]: {
        name: '全部地区',
        oneLevel: 0
    }
}

export const configStatus = {
    title: '选择订阅器类型',
    selectConfig: [
        { id: 12, name: '标讯' },
        { id: 3, name: '拟在建项目' }
    ]
}