require("console-polyfill");
import axios from "axios";
import { message, Modal } from "antd";
import cookie from "react-cookies";
import { createFromIconfontCN } from "@ant-design/icons";
import { getQueryVariable } from '@/utils/utils'

import {dataApi} from './config'

const apiUrl = typeof window !== 'undefined' ? (process.env.NODE_ENV === 'development' ? window.env.CALL_URL : window.options?.API_URL) : '';
// 获取问号传参
export function getQuestionMark(url) {
    let mark
    if (url.indexOf("?") != -1) {
         const str = url.substr(1)
         mark= str.split('=')[1]
    }  
    return mark
}

// 判断是否为微信环境
export function isWeChat() {
    if (typeof window == 'undefined') {
        return false;
    }
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    const nreWindow = typeof window == "undefined" ? global : window
    const ua = nreWindow.navigator && nreWindow.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    return !!(ua && ua.match(/MicroMessenger/i) == 'micromessenger')
}

export function isAndroidIos() {
    if (typeof window == 'undefined') {
        return false;
    }
    const u =  window.navigator && window.navigator.userAgent;
    let isAndroid;
    let isIos;
    if (u) {
        isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        isIos = (/ipad|iphone/i.test(u)); //ios终端
    }

    return {
        isAndroid,
        isIos
    }

}

export function getCookie(name) {
    return cookie.load(name)||null
}

export function getCookieSuite() {
    return getCookie('suite') && getCookie('suite').suiteCode
}

export function setCookie(name, value) {
    cookie.save(name,value,{ path: '/' })
}

function getCookieStaus(tenderMobileToken) {
    // app H5 通过问号穿参 获取token
    if(document.location.pathname.indexOf('AppAnnounce') > -1 && getQueryVariable('token')) {
       const token = getQueryVariable('token');
       const newToken = token.replace('bearer', '').trim() || {}
       return `bearer ${newToken}`
    }
    if(tenderMobileToken) {
        return `Bearer ${tenderMobileToken}`
    }
    return {}

}
const getPlatform=()=>{
    if(localStorage.getItem('qkSourceType')==='wechat'||getCookie('qkSourceType')==='wechat'){
        return 'wechat'
    }
    if(isAndroidIos().isAndroid){
        return 'Android'
    }
    if(isAndroidIos().isIos){
        return 'IOS'
    }
    return 'H5'
}
export const removeLocalStorage = () => {
    localStorage.removeItem('nicList')
    localStorage.removeItem('regionData-toal')
    localStorage.removeItem('provincesData')
    localStorage.removeItem('regionDataEntries')
}

// 发起请求前
const loadingInstance = null;
axios.interceptors.request.use(
    function (config) {

        //每个请求增加时间戳，防止IE8缓冲
        const t = new Date().getTime();
        
        config.url = config.url || ''
        config.url =
            config.url.indexOf("?") == "-1" ? config.url + "?_=" + t : config.url + "&_=" + t;

        /*
         *   在发送请求之前做些什么
         * if (config.method === "post") {
         *     config.headers["Content-Type"] = "application/x-www-form-urlencoded";
         *     config.data = qs.stringify(config.data);
         * }
         */
        // 超时时间
        config['timeout'] = 30000;

        // 默认开启loading
        if (!config.LOADINGHIDE && !loadingInstance) {
            // loadingInstance = message.loading("正在执行中...", 0);
        }
        try {
            config.headers.productId = 20;
            config.headers.qkSourceType = getPlatform()
            config.headers.Authorization = getCookieStaus(getCookie("tenderMobileToken")||localStorage.getItem('tenderMobileToken'))
            // 不需要获取域名
            if(config.url.indexOf('/app/bid/v1/bid') !== -1) {
                return {
                    ...config, url: config.url
                }
            }
            
            // 后台 data 项目下接口不需要  bid
            let ifDataApi = dataApi.some(item => {
                let currentUrl = config.url.split('?')[0];
                return currentUrl === item
            })
            return { 
                ...config,
                url: (config.url.indexOf('/mock/') !== -1 ? '' : `${apiUrl || ''}/api${ifDataApi?'':'/bid'}`) + config.url
            }
        } catch (e) {
            console.log(e, "=== error");
        }
    },
    function (error) {
        // SysModal.error("加载超时");
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        if (response.status === 200 && response.data) {
            if (response?.data?.code === '2016004') {
                cookie.remove('tenderMobileToken',{ path: '/' })
                return Modal.error({
                    title:"下线通知",
                    content:(
                        <div>
                            您的账号在另一地点登录，已被迫下线
                        </div>
                    ),
                    onOk:()=>{
                        let loginFUnc = require("../utils/login");
                        loginFUnc.login();
                        // window.location.href="http://localhost:3000/login"
                    }
                })
            } else {
                return response.data; // 成功处理
            }
        } else if (response.status === 401 || response.status === 403 ) {
            // window.location.href="http://localhost:3000/login"
            let loginFUnc = require("../utils/login");
            loginFUnc.login();
        } else {
            //错误处理
            if (response.data) {
                if (response.data.code === 100 && /^视图\S*重复！$/.test(response.data.message)) {
                    return message.warning(response.data.message);
                } else {
                    message.error(response.data.message);
                }
            } else {
                message.error("请求错误，请重试！");
            }
            console.warn("axios请求错误：", response);
        }
    },
    function (error) {
        loadingInstance && loadingInstance();
        if (error) {
            // 请求配置发生的错误
            const { status } = error.response || {};
            console.error("axios请求错误：", error);
            // 错误状态处理
            if (status === 401) {
                let loginFUnc = require("../utils/login");
                loginFUnc.login();
            } else if (status === 403) {
                //config 是主路由, 需要展示tab导航
                const config = ['UserCenter', 'MonitorCenter', 'SubscribeCenter', 'workbench']
                const pathname = location.pathname;
                const name = pathname.replace(/\//g, '');
                // 默认二级路由
                let url = `/NoAccessView?${name}`;
                // 导航路由器
                config.forEach(item => {
                    const newItem = item.replace(/\//g, '')
                    if(item === name) {
                        url = `/NoAccessViewTab?${newItem}`;
                    }
                })

                 // 首页导航 特殊
                if (!pathname || pathname === '/') {
                    url = "/NoAccessViewTab?workbench"
                }

                error.status === 200 && error.data
                
                if (pathname.indexOf('/CollectionCenter') === -1  &&
                    pathname.indexOf('/MonitorCenter') === -1 &&
                    pathname.indexOf('/SubscribeCenter') === -1 ) {
                        window.location.href = url
                }

            } else if (status >= 404 && status < 422) {
                // router.push("/404");
            }
        }

        return Promise.reject(error);
    }
);

export const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_2795761_91915xja45p.js",
});

export { axios };
