/*
 * @Description: 多处引用数据方法
 * @Autor: wushabo
 * @Date: 2022-04-19 
 */
import { axios } from 'routes/utils';
import {
  getInfoType
} from '@/modules/common/information';
import {
    searchTypeApi
} from '@/routes/common/config'
//获取信息类型数据
export const getInformationData = () => (dispatch) => {
    axios.get(searchTypeApi).then(res => {
      const {data, code} = res;
      if (code === '200') {
        let treeData = [];
        (data || []).forEach(item =>{
          item.code = item.subCodes || item.code;
          item.value = item.subCodes || item.code;
          item.label = item.name;
          item.children = data.filter(v => v.parent === item.id);
          item.children.unshift({
              code: item.code,
              label: '全部',
              level: "1",
              name: '全部',
              parent: "",
              value: item.code,
              newName: item.name,
              id: item.id,
              all: true
          })
          //  找父节点
          data.filter(v => item.parent === v.id ).length===0?treeData.push(item):''
            
        })
        dispatch(getInfoType({
          infoType: treeData
        }))
      }
    })


    // Promise.all([axios.get(searchTypeApi1), axios.get(searchTypeApi2)]).then(res => {
    //   try {
    //     const res1= res[0];
    //     const res2 = res[1];
    //     const infoTypes1 = res1.data.infoTypes;
    //     infoTypes1[0].value = "1";
    //     const infoTypes2 = res2.data.infoTypes;
    //     infoTypes2[0].value = "2";
    //     if (res1.code === '200') {
    //       dispatch(getSearchType1({
    //         searchType1: [...infoTypes1]
    //       }))
    //     }
    //     if (res2.code === '200') {
    //       dispatch(getSearchType2({
    //         searchType2: [...infoTypes2]
    //       }))
    //     }
    //   } catch (e) {
    //     console.log(e)

    //   }
       
    //   })
}
