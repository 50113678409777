// export const GET_TOTAL_NEWS = 'subscribe/GET_TOTAL_NEWS';
// export const GET_TOTAL_SUBSCRIBE = 'subscribe/GET_TOTAL_SUBSCRIBE';
// export const GET_SUBSCRIBE_SEARCH = 'subscribe/GET_SUBSCRIBE_SEARCH';
// export const SUBSCRIBEE_SERARCH = 'subscribe/SUBSCRIBEE_SERARCH';
// export const SUBSCRIBEE_SERARCH_PAGE = 'subscribe/SUBSCRIBEE_SERARCH_PAGE';
// export const SET_CREATE_TIME = 'subscribe/SET_CREATE_TIME';
// export const SET_PAGE_NUMBER = 'subscribe/SET_PAGE_NUMBER';
// export const GET_DATA_BACKFILL = 'subscribe/GET_DATA_BACKFILL';
// export const GET_DATA_BACKFILL_CLOSE = "subscribe/GET_DATA_BACKFILL_CLOSE";
// export const HAS_MORE = "subscribe/HAS_MORE";
// export const IS_FIRST_SUBSCRIBE = "subscribe/IS_FIRST_SUBSCRIBE";
// export const SET_PAGE_LOADING = "subscribe/SET_PAGE_LOADING";
// export const CHANGE_SUBSCRIBE_PROJECT_DETAILS = 'subscribe/CHANGE_SUBSCRIBE_PROJECT_DETAILS';


export const GET_DATALIST_SERARCH = 'groupPurchase/GET_DATALIST_SERARCH'


const initialState = {
    dataList: [], // 列表数据
    hasMore: true, // 是否下滑请求数据
    count: 0,
    page: 1,
    loading: false
};


export default (state = initialState, action={}) => {
    switch (action.type) {
        // case SET_PAGE_LOADING:
        //     return {
        //         ...state,
        //         loading: action.loading
        //     }
        // case HAS_MORE:
        //     return {
        //         ...state,
        //         hasMore: false
        //     }
        // case SET_PAGE_NUMBER:
        //     return {
        //         ...state,
        //         page: action.page
        //     }
        // case SUBSCRIBEE_SERARCH_PAGE:
        //     return {
        //         ...state,
        //         dataList: [...state.dataList, ...action.dataList],
        //         hasMore: true
        //     }
        case GET_DATALIST_SERARCH:
            return {
                ...state,
                dataList: action.dataList,
                page: action.page,
                count: action.count,
                hasMore: true
            }
      
       
        // case GET_SUBSCRIBE_SEARCH:
        //     return {
        //         ...state,
        //         searchData: action.searchData
        //     }
        default:
           return state
    }
}


// // loading
// export const setPageLoading = ownProps => dispatch => {
//     new Promise(resolve => {
//         dispatch({
//             type: SET_PAGE_LOADING,
//             loading: ownProps.loading
//         })
//     })
// }


// // 第一次进入订阅
// export const changeFirstSubscribe = ownProps => dispatch => {
//     new Promise(resolve => {
//         dispatch({
//             type: IS_FIRST_SUBSCRIBE
//         })
//     })
// }
// // 下滑数据没回来, 不请求数据
// export const changHasMore = ownProps => dispatch => {
//     new Promise(resolve => {
//         dispatch({
//             type: HAS_MORE
//         })
//     })
// }

// // 下拉页数更新
// export const getPagStatus = ownProps => dispatch => {
//     new Promise(resolve => {
//         dispatch({
//             type: SET_PAGE_NUMBER,
//             page: ownProps.page
//         })
//     })
// }


// export const getSubscribeSearchPageData = ownProps => dispatch => [
//     new Promise(resolve => {
//         dispatch({
//             type: SUBSCRIBEE_SERARCH_PAGE,
//             dataList: ownProps.dataList,
//             subscribePage: ownProps.subscribePage,
//             subscribeCount: ownProps.subscribeCount,
//             hasMore: true
//         })
//     })
// ]

// 获取数据
export const saveDataList = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_DATALIST_SERARCH,
            dataList: ownProps.dataList,
            page: ownProps.page,
            count: ownProps.count,
            hasMore: true
       })
    })
}


// // 数据列表
// export const setSubscribeSearch = ownProps => dispatch => {
//     new Promise(resolve => {
//         dispatch({
//             type: GET_SUBSCRIBE_SEARCH,
//             searchData: ownProps.searchData
//         })
//     })
// }
















