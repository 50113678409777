export const runtimeConfig = typeof window !== 'undefined' ? {
// client
CALL_URL: window.env.CALL_URL,
THIRD_LOGIN:window.env.THIRD_LOGIN,
ENV_URL: window.env.ENV_URL,
CALLBACK_URL: window.env.CALLBACK_URL,
scriptUrl: window.env.scriptUrl,
PUBLIC_PATH: window.env.PUBLIC_PATH,
NODE_ENV: window.env.NODE_ENV,
RAZZLE_SERVER_ON: window.env.RAZZLE_SERVER_ON,
} : {
// server
CALL_URL: process.env.CALL_URL,
ENV_URL: process.env.ENV_URL || 'http://test.qike366.cn',
THIRD_LOGIN:process.env.THIRD_LOGIN,
CALLBACK_URL: process.env.CALLBACK_URL || `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?
                                           appid=3Dww44d7c49e4be1eaf5&redirect_uri=https://scrm-saas-test.qike366.cn/user/official&usertype=member`,
scriptUrl: process.env.scriptUrl || `//at.alicdn.com/t/font_2537297_tlkdjwpoa6k.js,//at.alicdn.com/t/font_2585985_sb4ug1153xa.js,
                                    //at.alicdn.com/t/font_2537297_tlkdjwpoa6k.js,//at.alicdn.com/t/font_2795761_kpmnt80wnsb.js`, // 运行时默认值在这设置
PUBLIC_PATH: process.env.PUBLIC_PATH || '/',
NODE_ENV: process.env.NODE_ENV,
RAZZLE_SERVER_ON: process.env.RAZZLE_SERVER_ON || false,
};

