export const CHANGE_TIME_VALUE = 'time/CHANGE_TIME_VALUE';
export const CHSNGE_REASE_LIMIT = 'time/CHSNGE_REASE_LIMIT';
export const INITIALA_TATE = 'time/INITIALA_TATE';

const initialState = {
    releaseTime: 'UNLIMITED',
    releaseTimeLowerLimit: '',
    releaseTimeUpperLimit: ''
}

export default (state: any, action: any) => {
    state = state || initialState;
    switch (action.type) {
        case CHANGE_TIME_VALUE:
            return {
                ...state,
                releaseTime: action.releaseTime
            }
        case CHSNGE_REASE_LIMIT:
        return {
            ...state,
            releaseTimeLowerLimit: action.releaseTimeLowerLimit,
            releaseTimeUpperLimit: action.releaseTimeUpperLimit
        }
        case INITIALA_TATE:
            return {
                ...state,
                ...initialState
            }
        
        default:
            return state;
    }
   
}

export const initialStateTime = (ownProps: any) => {
    return {
        type: INITIALA_TATE
    }
}

export const changeReleaseTime = (ownProps: any) => {
    return {
        type: CHANGE_TIME_VALUE,
        releaseTime: ownProps.releaseTime
    }

}

export const changeReleaseTimeLimit = (ownProps: any) => {
    return {
        type: CHSNGE_REASE_LIMIT,
        releaseTimeLowerLimit: ownProps.releaseTimeLowerLimit,
        releaseTimeUpperLimit: ownProps.releaseTimeUpperLimit
    }
}