import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { systemUtils } from '@/utils/utils';

const { getPath } = systemUtils;

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ "./Login"),
  loading: () => null,
  modules: ["Login"],
});

const Register = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ "./Login/register"),
  loading: () => null,
  modules: ["Register"],
});

const InputCompanyName = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ "./Login/inputCompanyName"),
  loading: () => null,
  modules: ["InputCompanyName"],
});


const HistoryCenter = Loadable({
  loader: () => import(/* webpackChunkName: "HistoryCenter" */ "./HistoryCenter"),
  loading: () => null,
  modules: ["HistoryCenter"],
});

const CollectionCenter = Loadable({
  loader: () => import(/* webpackChunkName: "CollectionCenter" */ "./CollectionCenter"),
  loading: () => null,
  modules: ["CollectionCenter"],
});

// const SelectLocation = Loadable({
//   loader: () => import(/* webpackChunkName: "SelectLocation" */ "./Workbench/SelectLocation"),
//   loading: () => null,
//   modules: ["SelectLocation"],
// })

const Workbench = Loadable({
  loader: () => import(/* webpackChunkName: "Workbench" */ "./Workbench/index"),
  loading: () => null,
  modules: ["Workbench"],
});

// 需要底部导航栏的页面
const UserCenter = Loadable({
  loader: () => import(/* webpackChunkName: "UserCenter" */ "./UserCenter"),
  loading: () => null,
  modules: ["UserCenter"],
});


const MonitorCenter = Loadable({
  loader: () => import(/* webpackChunkName: "MonitorCenter" */ "./MonitorCenter"),
  loading: () => null,
  modules: ["MonitorCenter"],
});

// 企业列表
const MonitorCompany = Loadable({
  loader: () => import(/* webpackChunkName: "MonitorCompany" */'./MonitorCenter/company'),
  loading: () => null,
  modules: ["MonitorCompany"],
})
//  添加企业
const MonitorCompanyAdd = Loadable({
  loader: () => import(/* webpackChunkName: "MonitorCompanyAdd" */'./MonitorCenter/addCompany'),
  loading: () => null,
  modules: ["MonitorCompanyAdd"],
})

const SubscribeCenter = Loadable({
  loader: () => import(/* webpackChunkName: "SubscribeCenter" */ "./SubscribeCenter"),
  loading: () => null,
  modules: ["SubscribeCenter"],
});

const SubscribeManage = Loadable({
  loader: () => import(/* webpackChunkName: "SubscribeManage" */ "./SubscribeCenter/SubscribeManage"),
  loading: () => null,
  modules: ["SubscribeManage"],
})

const NewSubscribe = Loadable({
  loader: () => import(/* webpackChunkName: "NewSubscribe" */ "./SubscribeCenter/newSubscribe"),
  loading: () => null,
  modules: ["NewSubscribe"],
})

// 项目详情
const ProjectDetails = Loadable({
  loader: () => import(/* webpackChunkName: "ProjectDetails" */ "./ProjectDetails"),
  loading: () => null,
  modules: ["ProjectDetails"],
});

// OLD企业详情
const OldEnterpriseDetails = Loadable({
  loader: () => import(/* webpackChunkName: "EnterpriseDetails" */ "./oldEnterpriseDetails"),
  loading: () => null,
  modules: ["oldEnterpriseDetails"],
});

// 企业详情
const EnterpriseDetails = Loadable({
  loader: () => import(/* webpackChunkName: "EnterpriseDetails" */ "./Enterprise/EnterpriseDetails"),
  loading: () => null,
  modules: ["EnterpriseDetails"],
});

// 企业详情-->项目列表
const ProjectLists = Loadable({
  loader: () => import(/* webpackChunkName: "ProjectLists" */ "./oldEnterpriseDetails/ProjectLists"),
  loading: () => null,
  modules: ["ProjectLists"],
});

// 个人中心-->特权详情
const PrivilegeDeatils = Loadable({
  loader: () => import(/* webpackChunkName: "PrivilegeDeatils" */ "./UserCenter/PrivilegeDeatils"),
  loading: () => null,
  modules: ["PrivilegeDeatils"],
});

// 地图
const AMap = Loadable({
  loader: () => import(/* webpackChunkName: "AMap" */ "./AMap"),
  loading: () => null,
  modules: ["AMap"],
});

// app
const AppAnnounce = Loadable({
  loader: () => import(/* webpackChunkName: "App" */ "./AppAnnounce"),
  loading: () => null,
  modules: ["AppAnnounce"],
})

/**
 * @description: 首页搜索
 * @author:  luyiru
*/
const Search = Loadable({
  loader: () => import(/* webpackChunkName: "SubscribeCenter" */ "./Workbench/search/search"),
  loading: () => null,
  modules: ["Search"],
})

const NoAccessView = Loadable({
  loader: () => import(/* webpackChunkName: "SubscribeCenter" */ "./components/NoAccessView"),
  loading: () => null,
  modules: ["NoAccessView"],
})

const IframePage = Loadable({
  loader: () => import(/* webpackChunkName: "IframePage" */ "./IframePage"),
  loading: () => null,
  modules: ["IframePage"],
})

const EnterpriseList = Loadable({
  loader: () => import(/* webpackChunkName: "MessageList" */ "./Enterprise/EnterpriseList"),
  loading: () => null,
  modules: ["MessageList"],
})

// 企业详情-->联系方式
const EnterContactList = Loadable({
  loader: () => import(/* webpackChunkName: "EnterContactList" */ "./Enterprise/EnterContactList"),
  loading: () => null,
  modules: ["EnterContactList"],
});

const rojectList = Loadable({
  loader: () => import(/* webpackChunkName: "MessageList" */ "./Enterprise/ProjectList"),
  loading: () => null,
  modules: ["MessageList"],
})

const SpecialArea = Loadable({
  loader: () => import(/* webpackChunkName: "SpecialArea" */ "./Workbench/SpecialArea"),
  loading: () => null,
  modules: ["SpecialArea"],
})

const Application = Loadable({
  loader: () => import(/* webpackChunkName: "SpecialArea" */ "./Application"),
  loading: () => null,
  modules: ["Application"],
})

const Recommend = Loadable({
  loader: () => import(/* webpackChunkName: "SpecialArea" */ "./Application/Recommend"),
  loading: () => null,
  modules: ["Recommend"],
})

const GroupPurchase = Loadable({
  loader: () => import(/* webpackChunkName: "SpecialArea" */ "./GroupPurchase"),
  loading: () => null,
  modules: ["GroupPurchase"],
})

const LoginBychsme = Loadable({
  loader: () => import(/* webpackChunkName: "SpecialArea" */ "./Loginbychsme"),
  loading: () => null,
  modules: ["Loginbychsme"],
})
const LoginByoem = Loadable({
  loader: () => import(/* webpackChunkName: "SpecialArea" */ "./Loginbyoem"),
  loading: () => null,
  modules: ["Loginbyoem"],
})

export const ExternalRoutes = () => (
  <Switch>
    <Route exact path={getPath("/Login")} component={Login} />
    <Route path={getPath('/HistoryCenter')} component={HistoryCenter} />
    <Route path={getPath('/CollectionCenter')} component={CollectionCenter} />
    {/* <Route exact path={getPath("/SelectLocation")} component={SelectLocation} /> */}
    <Route path={getPath('/SubscribeCenter/SubscribeManage')} component={SubscribeManage} />
    <Route path={getPath('/SubscribeCenter/newSubscribe')} component={NewSubscribe} />
    <Route path={getPath('/MonitorCenter/company')} component={MonitorCompany} />
    <Route path={getPath('/MonitorCenter/addCompany')} component={MonitorCompanyAdd} />
    <Route path={getPath('/oldEnterpriseDetails/ProjectLists')} component={ProjectLists} />
    <Route path={getPath('/UserCenter/PrivilegeDeatils')} component={PrivilegeDeatils} />
    <Route path={getPath("/AMap")} component={AMap} />
    <Route path={getPath("/register")} component={Register} />
    <Route path={getPath("/inputCompanyName")} component={InputCompanyName} />
    <Route path={getPath("/NoAccessView")} component={NoAccessView} /> 
    <Route path={getPath('/search')} component={Search} />
    <Route path={getPath('/ProjectDetails')} component={ProjectDetails} />
    <Route path={getPath('/oldEnterpriseDetails')} component={OldEnterpriseDetails} />
    <Route path={getPath('/IframePage')} component={IframePage} />
    <Route path={getPath('/AppAnnounce')} component={AppAnnounce} />
    <Route path={getPath('/Enterprise/EnterpriseDetails')} component={EnterpriseDetails} />
    <Route path={getPath('/Enterprise/EnterpriseList')} component={EnterpriseList} />
    <Route path={getPath('/Enterprise/ProjectList')} component={rojectList} />
    <Route path={getPath('/Enterprise/EnterContactList')} component={EnterContactList} />
    <Route path={getPath('/SpecialArea')} component={SpecialArea} />
    <Route path={getPath('/MonitorCenter')} component={MonitorCenter} />
    <Route path={getPath('/Application/Recommend')} component={Recommend} />
    <Route path={getPath('/GroupPurchase')} component={GroupPurchase} />
    <Route path={getPath('/user/loginbychsme')} component={LoginBychsme} />
    <Route path={getPath('/user/loginbyoem')} component={LoginByoem} />
    
  </Switch>
);

export default () => (
  <div>
     <Switch>
    {/* <Route exact path={getPath("/Login")} component={Login} /> */}
    <Route path={getPath('/UserCenter')} component={UserCenter} />
    <Route  path={getPath('/SubscribeCenter')} component={SubscribeCenter} />
    <Route path={getPath('/workbench')} component={Workbench} />
    <Route path={getPath('/Application')} component={Application} />
    {/* <Route path={getPath('/MonitorCenter')} component={MonitorCenter} /> */}
    <Route path={getPath('/NoAccessViewTab')} component={NoAccessView} />
    <Redirect exact from={getPath('/')} to='workbench' />

  </Switch>
  </div>
 
)
