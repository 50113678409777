/*
 * @Description:
 * @Autor: wushaobo
 * @Date: 2021-11-10
 */

export const TABS_SWITCH = 'tabs/TABS_SWITCH';

const initialState = {
    activeKey: 'workbench'
}

export default (state = initialState, action = {}) => {
    if (action.type === TABS_SWITCH) {
        return {
            ...state,
            activeKey: action.activeKey
        }
    }
    return state;
}

export const changeTabs = ownProps => {
    return {
        type: TABS_SWITCH,
        activeKey: ownProps.activeKey
    }
}



export const changeNewTabs = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: TABS_SWITCH,
            activeKey: ownProps.activeKey
        })
    })
}
