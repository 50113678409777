/*
 * @Description:微信登录
 * @Autor: sunshaoqing
 * @Date: 2021-10-08 19:51:09
 */
import { getQueryVariable } from './utils';
import { getSystem } from './helps';
import { axios, setCookie, getCookie, removeLocalStorage } from '../routes/utils';
import { Toast } from 'antd-mobile';
import { systemUtils } from 'utils/utils';

const { getPath } = systemUtils;
let loginMark = false;

if (!getCookie('tenderMobileToken')) {
  login();
}

export function funcUrlDel(names) {
  if (typeof window == 'undefined') {
    return;
  }
  if (typeof names == 'string') {
    names = [names];
  }
  let loca = window.location;
  let obj = {};
  let arr = loca.search.substr(1).split('&');
  let i;

  //获取参数转换为object
  for (i = 0; i < arr.length; i++) {
    arr[i] = arr[i].split('=');
    obj[arr[i][0]] = arr[i][1];
  }
  //删除指定参数
  for (i = 0; i < names.length; i++) {
    delete obj[names[i]];
  }
  //重新拼接url
  var url =
    loca.origin +
    loca.pathname +
    '?' +
    JSON.stringify(obj)
      .replace(/[\"\{\}]/g, '')
      .replace(/\:/g, '=')
      .replace(/\,/g, '&');
  window.history.replaceState(null, null, url); //替换地址栏
  return url;
}

export function login() {
  if (typeof location == 'undefined') {
    return;
  }
  const appcode = localStorage.getItem('appcode') || getCookie('appcode');
  if (appcode === 'bidjj') {
    Toast.show('登录超时');
    return;
  }
  if (loginMark) {
    return false;
  }
  loginMark = true;

  removeLocalStorage()
  if(getPath('/user/loginbychsme').includes(window.location.pathname.replace(/\/$/, ''))){
    return false
  }
  if(getPath('/user/loginbyoem').includes(window.location.pathname.replace(/\/$/, ''))){
    return false
  }
  if (
    getPath('/login').includes(window.location.pathname.replace(/\/$/, '')) ||
    getPath('/register').includes(window.location.pathname.replace(/\/$/, ''))
  ) {
    let code = getQueryVariable('code');
    if(code){
      return false;
    }
  }

  if (getSystem() == 'wx') {
    let code = getQueryVariable('code');
    funcUrlDel('code');
    if (code) {
      //获取到code调取后台接口获取用户信息
      axios.get(`/account/security/snsapi-base-code?code=${code}`, {}).then((res) => {
        if (res.code == '200') {
          if (res.data == '') {
            //没有绑定手机号
            location.replace(getPath('/login?code=' + code));
          } else {
            setLogin(res);
          }
        } else if (res.code == '2016016') {
          location.replace(getPath('/login?code=' + code));
        }
      });
    } else {
      let href = location.href;    
      let appid = "wx3f11330d155f4437";
    
      if(href.indexOf('m-uat') > -1) {
        appid = 'wx14cb3c88b8e34519'
      }
      if(href.indexOf('login') > -1) {
        href = href.replace('login', 'workbench')
      }
      location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(href)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      );
    }
  } else {
    if (
      getPath('/login').includes(window.location.pathname.replace(/\/$/, '')) ||
      getPath('/register').includes(window.location.pathname.replace(/\/$/, ''))||
      getPath('/user/loginbychsme').includes(window.location.pathname.replace(/\/$/, ''))||
      getPath('/user/loginbyoem').includes(window.location.pathname.replace(/\/$/, ''))
    ) {
      return false;
    }
    location.replace(getPath('/login'));
  }
}

//保存用户信息
export function setLogin(data) {
  if (typeof location == 'undefined') {
    return;
  }
  //绑定过手机号
  setCookie('tenderMobileToken', data?.data?.token);
  axios.post(`/account/center/main-info`, {}).then((res) => {
    if (res.code == '200') {
      let userInfo = {...res.data.user,resources:''} 
      setCookie('user', JSON.stringify(userInfo));
      setCookie('suite', JSON.stringify(res.data.suite));
      setCookie('company', JSON.stringify(res.data.company));
      //回调地址
      let redirectUrl = getQueryVariable('redirect_uri');
      if (
        redirectUrl &&
        redirectUrl.indexOf('/login') == -1 &&
        redirectUrl.indexOf('/register') == -1
      ) {
        location.replace(getPath(redirectUrl));
      } else if (location.href.indexOf('/login') > -1 || location.href.indexOf('/register') > -1) {
        location.replace(getPath('/workbench'));
      } else {
      //  location.reload();
      }
    } else if (res.code == '2016016') {
      Toast.show(res.msg || '失败');
    }
  });
}

setTimeout(() => {
  loginMark = false;
}, 1000);
