import { inputTab } from '@/routes/Workbench/config';

export const PROJECT_DATA = 'search/PROJECT_DATA'
export const TAB_TYPE = "search/TAB_TYPE";
export const IS_BACKFILL = "search/IS_BACKFILL";
export const IS_BACKFILL_INITIA = "search/IS_BACKFILL_INITIA";
export const IS_SEARCH_ENTERED = "search/IS_SEARCH_ENTERED";
export const SEARCH_VALUE = "search/SEARCH_VALUE";
export const SET_ALL_DATA = "search/SET_ALL_DATA";
export const CHANGE_PAGE = 'searchResult/CHANGE_PAGE'; // 页码
export const SEARCH_TYPE = 'searchResult/SEARCH_TYPE';
// SearchResult
export const GET_PROJECTS_DATA = 'searchResult/GET_PROJECTS_DATA'; // 查项目
export const GET_PROJECTS_COUNT = 'searchResult/GET_PROJECTS_COUNT';
export const CHANGE_PROJECTS_LOEADING = 'searchResult/CHANGE_PROJECTS_LOEADING'; // 项目loading

export const GET_ENTER_PRISE_DATA = 'searchResult/GET_ENTER_PRISE_DATA'; // 查企业
export const GET_ENTER_PRISE_COUNT = 'searchResult/GET_ENTER_PRISE_COUNT';
export const GET_ENTER_PRISE_ACCUNU_DATA = 'searchResult/GET_ENTER_PRISE_ACCUNU_DATA'; // 查企业累加
export const GET_ENTER_PRISE_NAME_DATA = 'searchResult/GET_ENTER_PRISE_NAME_DATA'; // 企业对应的项目
export const CHANGE_ENTER_PRISE_LOADING = 'searchResult/CHANGE_ENTER_PRISE_LOADING'; // 企业loading
export const GET_PRODUCT_DATA = 'searchResult/GET_PRODUCT_DATA';
export const GET_PRODUCT_COUNT = 'searchResult/GET_PRODUCT_COUNT';
export const GET_PRODUCT_PAGE_DATA = 'searchResult/GET_PRODUCT_PAGE_DATA';

//企业搜索 名称
export const CHANGE_INPUT_ENTERPRISE = 'searchResult/CHANGE_INPUT_ENTERPRISE';
// 地域数据回填
export const GET_REGION_CALL_BACK = 'searchResult/GET_REGION_CALL_BACK';
// 信息类型回填
export const SET_PROJECT_IN_DEFAULT = 'searchResult/SET_PROJECT_IN_DEFAULT';
// 时间回填
export const SET_DEFAULT_VALUTE = 'searchResult/SET_DEFAULT_VALUTE';
// 更多筛选回填
export const SET_MORE_DEFAULT = 'searchResult/SET_MORE_DEFAULT';
// 行业筛选
export const SET_INFO_TYPE_SAVE = 'searchResult/SET_INFO_TYPE_SAVE';
// 企业角色回填
export const SET_ENTERPRISE_DEFAULT = 'searchResult/SET_ENTERPRISE_DEFAULT';
// 首页地域数据回填
export const ADD_WORKKBENCH_REGIN = 'searchResult/ADD_WORKKBENCH_REGIN';
// 多级行业数据 
export const GET_INDUSTRY_LIST = 'searchResult/GET_INDUSTRY_LIST';
// 数据初始化
export const INITIALIZE_LIST = 'searchResult/INITIALIZE_LIST';
// 第一次进入
export const IS_INITIALIZE = 'searchResult/IS_INITIALIZE'
// 是否 热搜页面
export const IS_HOT_PAGE = 'searchResult/IS_HOT_PAGE';
export const GET_INPUT_VALUE = 'searchResult/GET_INPUT_VALUE';
// 一键订阅参数
export const ONE_CLICK_SUBSCRIBE = 'searchResult/ONE_CLICK_SUBSCRIBE'
// 是否添加条数限制
export const GET_PROJECTS_SUITELIMIT =  'searchResult/是否添加条数限制'

const initialState = {
  industryList: [],
  addWorkbenchRegin: {}, // 首页地域数据回填
  enterpriseDefailt: [], // 企业角色回填
  infoTypeDefailt: [], // 行业筛选
  
  moreDefailt: {}, // 更多筛选
  
  timeDefaultValue: {}, // 时间回填
  
  backfillRegion: { // 地域回填
  },
  projectInDefault: [], // 信息类型回填
  searchValue: { // 搜索框 数据回显
    0: '',
    1: '',
    2: '',
    3: '',
  },
  isSearchEntered: {  // 是否搜索页进入
    0: true,
    1: true,
    2: true,
    3: true,
  },
  data: [],
  tab: 0,
  isBackfill: false,
  page: 1,
  listDataID: {
    mid: '',
    isCompany: false,
    isPoject: false,
    childId: ''
  },
  allData: {},
  searchType: '123', // 首页信息类型
  projectsData: [], // 查项目列表数据
  projectsCount: 0, // 查项目列表总数
  enterpriseData: [], // 企业列表
  enterpriseCount: 0, // 企业列表总数
  projectsNameData: {}, // 企业对应的项目
  projectsLoading: true, // 项目loading
  enterpriseLoading: true, // 查企业loading
  selectType: inputTab.product,
  isInitialize: true, // 是否第一次进入
  productData: [], // 产品列表
  productCount: 0, // 产品总数
  isHotPage: true,
  inputValue: '',
  oneClickSubscribeParams: {}, //一键订阅参数
  suiteLimit: false
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.inputValue
      }
    case IS_HOT_PAGE:
      return {
        ...state,
        isHotPage: action.isHotPage
      }
    case GET_PRODUCT_COUNT:
      return {
        ...state,
        productCount: action.productCount
      }
    case GET_PRODUCT_PAGE_DATA:
      return {
        ...state,
        productData: [...state.productData, ...action.productData]
      }
    case GET_PRODUCT_DATA:
      return {
        ...state,
        productData: action.productData
      }
    case IS_INITIALIZE:
      return {
        ...state,
        isInitialize: action.isInitialize
      }
    case INITIALIZE_LIST:
      return {
        ...state,
        moreDefailt: {},
        timeDefaultValue: {},
        backfillRegion: {},
        projectInDefault: []
      }
    case GET_INDUSTRY_LIST:
      return {
        ...state,
        industryList: action.industryList
      }
    case ADD_WORKKBENCH_REGIN:
      return {
        ...state,
        addWorkbenchRegin: action.addWorkbenchRegin
      }
    case SET_ENTERPRISE_DEFAULT:
      return {
        ...state,
        enterpriseDefailt: action.enterpriseDefailt
      }
    case SET_INFO_TYPE_SAVE:
      return {
        ...state,
        infoTypeDefailt: action.infoTypeDefailt
      }
    case SET_MORE_DEFAULT:
      return {
        ...state,
        moreDefailt: action.moreDefailt
      }
    case SET_DEFAULT_VALUTE:
      return {
        ...state,
        timeDefaultValue: action.timeDefaultValue
      }
    case SET_PROJECT_IN_DEFAULT:
      return {
        ...state,
        projectInDefault: action.projectInDefault
      }
    case GET_REGION_CALL_BACK:
      return {
        ...state,
        backfillRegion: action.backfillRegion
      }
    case CHANGE_INPUT_ENTERPRISE:
      return {
        ...state,
        selectType: action.selectType
      }
    case SEARCH_TYPE:
      return {
        ...state,
        searchType: action.searchType,
      }
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      }
    case CHANGE_PROJECTS_LOEADING:
      return {
        ...state,
        projectsLoading: action.projectsLoading
      }
    case CHANGE_ENTER_PRISE_LOADING:
      return {
        ...state,
        enterpriseLoading: action.enterpriseLoading
      }
    case GET_ENTER_PRISE_NAME_DATA:
      return {
        ...state,
        projectsNameData: { ...state.projectsNameData, ...action.projectsNameData }
      }

    case GET_ENTER_PRISE_ACCUNU_DATA:
      return {
        ...state,
        enterpriseData: [...state.enterpriseData, ...action.enterpriseData]
      }
    case GET_ENTER_PRISE_DATA:
      return {
        ...state,
        enterpriseData: action.enterpriseData
      }
    case GET_ENTER_PRISE_COUNT:
      return {
        ...state,
        enterpriseCount: action.enterpriseCount
      }
    case GET_PROJECTS_COUNT:
      return {
        ...state,
        projectsCount: action.projectsCount
      }
    case GET_PROJECTS_DATA:
      return {
        ...state,
        projectsData: action.projectsData
      }
    case SET_ALL_DATA:
      return {
        ...state,
        allData: action.allData
      }
    case SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue
      }
    case IS_SEARCH_ENTERED:
      return {
        ...state,
        isSearchEntered: action.isSearchEntered

      }
    case IS_BACKFILL_INITIA:
      return {
        ...state,
        isBackfill: false,
        listDataID: initialState.listDataID,
      }
    case GET_PROJECTS_SUITELIMIT:
      return {
        ...state,
        suiteLimit: action.suiteLimit
      }
    default:
      break;
  }
  switch (action.type) {
    case PROJECT_DATA:
      return {
        ...state,
        data: action.data
      }
    case TAB_TYPE:
      return {
        ...state,
        tab: action.tab
      }
    case IS_BACKFILL:
      return {
        ...state,
        isBackfill: action.isBackfill,
        listDataID: action.listDataID
      }
    case ONE_CLICK_SUBSCRIBE: {
      return {...state, oneClickSubscribeParams: action.values}
    }
    default:
      return state;
  }
}

// input value
export const getInputValue = (ownProps: any) => {
  return {
    type: GET_INPUT_VALUE,
    inputValue: ownProps.inputValue
  }
}

// 是否搜索页面
export const changeIsHotPage = (ownProps: any) => {
  return {
    type: IS_HOT_PAGE,
    isHotPage: ownProps.isHotPage
  }
}

// 产品分页数据
export const getProductPageData = (ownProps: any) => {
  return {
    type: GET_PRODUCT_PAGE_DATA,
    productData: ownProps.productData
  }
}

// 产品总数
export const getProductCount = (ownProps: any) => {
  return {
    type: GET_PRODUCT_COUNT,
    productCount: ownProps.productCount
  }
}

// 产品数据
export const getProductData = (ownProps: any) => {
  return {
    type: GET_PRODUCT_DATA,
    productData: ownProps.productData
  }

}


export const changeIsInitialize = (ownProps) => {
  return {
    type: IS_INITIALIZE,
    isInitialize: ownProps.isInitialize
  }
}

// 回填数据初始化
export const initializeBackfill = () => {
  return {
    type: INITIALIZE_LIST
  }
}
// 多级行业数据 
export const getIndustryList = (ownProps: any) => {
  return {
    type: GET_INDUSTRY_LIST,
    industryList: ownProps.industryList
  }

}
// 首页地域数据回填
export const getWorkbenchRegion = (ownProps: any) => {
  return {
    type: ADD_WORKKBENCH_REGIN,
    addWorkbenchRegin: ownProps.addWorkbenchRegin
  }

}
// 企业角色回填
export const getEnterpriseDefailt = (ownProps: any) => {
  return {
    type: SET_ENTERPRISE_DEFAULT,
    enterpriseDefailt: ownProps.enterpriseDefailt
  }
}
// 行业筛选回填
export const setInfoTypeSave = (ownProps: any) => {
  return {
    type: SET_INFO_TYPE_SAVE,
    infoTypeDefailt: ownProps.infoTypeDefailt
  }
}

// 更多筛选回填
export const setMoreDefault = (ownProps: any) => {
  return {
    type: SET_MORE_DEFAULT,
    moreDefailt: ownProps.moreDefailt
  }
}
// 时间回填
export const setTimeDefaultValue = (ownProps: any) => {
  return {
    type: SET_DEFAULT_VALUTE,
    timeDefaultValue: ownProps.timeDefaultValue
  }
}
// 地域回填
export const setBackfillData = (ownProps: any) => {
  return {
    type: GET_REGION_CALL_BACK,
    backfillRegion: ownProps.backfillRegion
  }
}
// 改变企业input 状态
export const changeSelectType = (ownProps: any) => {
  return {
    type: CHANGE_INPUT_ENTERPRISE,
    selectType: ownProps.selectType
  }

}
export const changeSearchType = (ownProps: any) => {
  return {
    type: SEARCH_TYPE,
    searchType: ownProps.searchType
  }
}
export const changePage = (ownProps: any) => {
  return {
    type: CHANGE_PAGE,
    page: ownProps.page
  }
}
export const changeProjectsLoading = (ownProps: any) => {
  return {
    type: CHANGE_PROJECTS_LOEADING,
    projectsLoading: ownProps.projectsLoading
  }
}
export const changeEnterpriseLoading = (ownProps: any) => {
  return {
    type: CHANGE_ENTER_PRISE_LOADING,
    enterpriseLoading: ownProps.enterpriseLoading
  }

}
export const getProjectsNameData = (ownProps: any) => {
  return {
    type: GET_ENTER_PRISE_NAME_DATA,
    projectsNameData: ownProps.data
  }
}

export const getEnterpriseAccnuList = (ownProps: any) => {
  return {
    type: GET_ENTER_PRISE_ACCUNU_DATA,
    enterpriseData: ownProps.enterpriseData
  }
}

export const getEnterpriseList = (ownProps: any) => {
  return {
    type: GET_ENTER_PRISE_DATA,
    enterpriseData: ownProps.enterpriseData
  }
}
export const getEnterpriseCount = (ownProps: any) => {
  return {
    type: GET_ENTER_PRISE_COUNT,
    enterpriseCount: ownProps.enterpriseCount
  }
}
export const getsearchProjectsCount = (ownProps: any) => {
  return {
    type: GET_PROJECTS_COUNT,
    projectsCount: ownProps.projectsCount
  }
}

export const getsearchProjectsData = (ownProps: any) => {
  return {
    type: GET_PROJECTS_DATA,
    projectsData: ownProps.data
  }
}

export const getSearchProjectsSuiteLimit = (ownProps:any) => {
  return {
    type: GET_PROJECTS_SUITELIMIT,
    suiteLimit: ownProps.suiteLimit
  }
}

export const setAllData = (ownProps: any) => {
  return {
    type: SET_ALL_DATA,
    allData: ownProps.allData
  }
}

export const changeSearchValue = (ownProps: any) => {
  return {
    type: SEARCH_VALUE,
    searchValue: ownProps.searchValue
  }
}
// 是否展示搜索页
export const changeSearchEntered = (ownProps: any) => {
  return {
    type: IS_SEARCH_ENTERED,
    isSearchEntered: ownProps.isSearchEntered
  }
}

export const changeBackfillInitial = (ownProps: any) => {
  return {
    type: IS_BACKFILL_INITIA
  }
}

export const getProjectData = (ownProps: any) => {
  return {
    type: PROJECT_DATA,
    data: ownProps.data,
  }
}



export const changTab = (ownProps: any) => {
  return {
    type: TAB_TYPE,
    tab: ownProps.tab,
  }
}



export const changeBackfillClick = (ownProps: any) => {
  return {
    type: IS_BACKFILL,
    isBackfill: ownProps.isBackfill,
    listDataID: ownProps.listDataID,
  }
}
// 信息类型回填
export const setProjectInDefault = (ownProps: any) => {
  return {
    type: SET_PROJECT_IN_DEFAULT,
    projectInDefault: ownProps.projectInDefault
  }

}

//获取一键订阅参数
export const getOneClickSubscribe = (ownProps:any) => {
  return {
    type: ONE_CLICK_SUBSCRIBE,
    values: ownProps.values
  }
}

