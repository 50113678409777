/*
 * @Description:
 * @Autor: sunshaoqing
 * @Date: 2021-10-14 15:58:13
 */

export const USER = 'USER';
export const COMPANY = 'COMPANY';
export const CODEVALUE = 'login/CODEVALUE';
export const PHONEVALUE = 'login/PHONEVALUE';
export const RESOURCES = 'login/RESOURCES';

const initialState = {
  companyName: '',
  loginPhoneValue: '',
  loginCodeValue: '',
  resources: []
};

export default (state = initialState, action?: any) => {
  switch (action.type) {
    case RESOURCES:
      return {
        ...state,
        resources: action.resources
      };
    case USER:
      return {
        ...state,
        ...action.payload ,
      };
    case COMPANY:
      return {
        ...state,
        companyName: action.companyName
      };
    case PHONEVALUE:
      return {
        ...state,
        loginPhoneValue: action.loginPhoneValue
      };
    case CODEVALUE:
      return {
        ...state,
        loginCodeValue: action.loginCodeValue
      };

    default:
      return state;
  }
};

// 保存页面用户页面权限
export const setResources = (ownProps:any) => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: RESOURCES,
            resources: ownProps.resources
        })
    })

// 注册完善信息选择公司
export const setCompanyName = (ownProps:any) => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: COMPANY,
            companyName: ownProps.companyName
        })
    })

// 注册手机号，补全信息页面用
export const setLoginPhoneValue = (ownProps:any) => dispatch => {
  new Promise(resolve => {
      dispatch({
          type: PHONEVALUE,
          loginPhoneValue: ownProps.loginPhoneValue
      })
  })
}
  

  // 注册验证码，补全信息页面用
export const setLoginCodeValue = (ownProps:any) => dispatch =>
  new Promise(resolve => {
      dispatch({
          type: CODEVALUE,
          loginCodeValue: ownProps.loginCodeValue
      })
  })

