import React, { useEffect } from 'react';
import classNames from 'classnames';
import IconFont from '@/components/IconFont';
import { systemUtils } from '@/utils/utils';
import connect, { target } from '@/components/connect';
import { changeTabs } from '@/modules/tabs'
import { setResources } from '@/modules/login'
import {
    changTab
} from '@/modules/workbench.tsx'
import './style.less'
import { axios } from '../../routes/utils';
import { isChsme, isOem } from 'utils/utils';


const { getPath, getUri } = systemUtils;

const pathNameConfig = ['workbench', 'SubscribeCenter', 'MonitorCenter', 'UserCenter']

const tabConfig = [
    {
        key: 'workbench',
        path: '/workbench',
        title: '首页',
        icon: <svg className="iconpark-icon"><use href="#shouyemoren"></use></svg>,
        highIcon: <svg className="iconpark-icon"><use href="#shouyexuanzhong"></use></svg>
    },
    {
        key: 'SubscribeCenter',
        path: '/SubscribeCenter',
        title: '订阅',
        icon: <svg className="iconpark-icon"><use href="#dingyuemoren"></use></svg>,
        highIcon: <svg className="iconpark-icon"><use href="#dingyuexuanzhong"></use></svg>
    },
    {
        key: 'Application',
        path: '/Application',
        title: '应用',
        icon: <svg className="iconpark-icon"><use href="#yingyongmoren"></use></svg>,
        highIcon: <svg className="iconpark-icon"><use href="#yingyongxuanzhong"></use></svg>
    },
    {
        key: 'UserCenter',
        path: '/UserCenter',
        title: '我的',
        icon: <svg className="iconpark-icon"><use href="#wodemoren"></use></svg>,
        highIcon: <svg className="iconpark-icon"><use href="#wodexuanzhong"></use></svg>
    }
]


function Tabs(props) {
    const {
        history,
        getAccountParams,
        activeKey,
        getAccounttab,
        setReduxResources
    } = props;
    // 页面刷新 查找路由


    function handleBack(newName) {
        getAccountParams(newName)
    }

    useEffect(() => {
        const pathName = history.location.pathname;
        const href = history.location.search;
        const name = pathName.replace(/\//g, '')
        getMainInfo()
        if (name) {
            getAccountParams(name)
        }
        if(href && href.indexOf('mark') == -1) {
          const path = href.replace("?", '')
          getAccountParams(path)


        }
         // 监听回退按钮
        window.addEventListener('popstate', function () {  
           const urlName = history.location.pathname;
           const newName = urlName.replace(/\//g, '')
            // 在回退时进行某种操作。
            if(pathNameConfig.indexOf(newName) > -1) {
                handleBack(newName)
            }

            return () => {
                window.removeEventListener('popstate', handleBack, false);
            }

           
        }, false);

    }, [])
    // 切换tab
    function changeTab(data) {
        getAccountParams(data.key)
        history.push(getPath(data.path))
    }


    function getMainInfo() {
        axios.post(`/account/center/main-info`, {}).then((res) => {
            const {code, data} = res
            const resources = data?.user?.resources || []
            if (code === '200') {
                setReduxResources(resources)
            }
          });
    }
    return (
        !isChsme()&&!isOem()&&
        <div className="tar-bars">
            <div className="tar-bars-box">
                {
                    tabConfig.map(item => {
                        const titleClassName = classNames({
                            'tar-bars-main': true,
                            'tar-bars-main-ligh': activeKey === item.key
                        })

                        return <div key={item.key} className={titleClassName} onClick={() => {
                            changeTab(item)
                            getAccounttab()
                        }}>
                            <span className="tar-bars-icon">
                                {
                                    activeKey === item.key ?
                                        <span className="tar-bars-icon-high">{item.highIcon}</span>
                                        : <span>{item.icon}</span>
                                }
                            </span>
                            <span className="tar-bars-title">{item.title}</span>
                        </div>
                    })
                }
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    const {
        activeKey
    } = state.tabs;

    const {
        resources
    } = state.login; 

    return {
        activeKey,
        resources
    }

}


const mapDispatchToProps = {
    getAccountParams: target('tab切换')((ownProps, key) => (dispatch, getState) => {
        dispatch(changeTabs({
            activeKey: key
        }))
    
    }),
    getAccounttab: target('tab切换')((ownProps, key) => (dispatch, getState) => {
    
        // 特殊逻辑, 点击首页 tab需要定位到项目
        if(key === "workbench") {
            dispatch(changTab({
                tab: 0
            }))
        }
    }),

    setReduxResources: target('tab切换')((ownProps, key) => (dispatch, getState) => {
        localStorage.setItem('resources', JSON.stringify(key))
        dispatch(setResources({
            resources: key
        }))
    
    })
}



export default connect(mapStateToProps, mapDispatchToProps)(Tabs)