export const PROJECT_DATA = 'workbench/PROJECT_DATA'
export const TAB_TYPE = "workbench/TAB_TYPE";
export const IS_BACKFILL = "workbench/IS_BACKFILL";
export const IS_BACKFILL_INITIA = "workbench/IS_BACKFILL_INITIA";
export const TAB_KEY = "workbench/TAB_KEY";
export const WORK_BENCH_PAGE = "workbench/WORK_BENCH_PAGE";
export const SIGNING_DATA = "workbench/SIGNING_DATA";
export const HAD_MORE = "workbench/HAD_MORE";
export const GET_ENTERPRISES_PROJECTS = "workbench/GET_ENTERPRISES_PROJECTS";
export const GET_ENTERPRISES_COUNT = "workbench/GET_ENTERPRISES_COUNT";
export const GET_SEARCH_PROJECT_DATA = "workbench/GET_SEARCH_PROJECT_DATA";
export const GET_SEARCH_PROJECT_TOTAL_DATA = "workbench/GET_SEARCH_PROJECT_TOTAL_DATA";
export const GET_SEARCH_PROJECT_COUNT = "workbench/GET_SEARCH_PROJECT_COUNT";
export const GET_SEARCH_PROJECT_PAGE= "workbench/GET_SEARCH_PROJECT_PAGE";
export const GET_SEARCH_PROJECT_HAS_MORE= "workbench/GET_SEARCH_PROJECT_HAS_MORE";
export const GET_PROJECT_HOT_WORDS = "workbench/GET_PROJECT_HOT_WORDS";
export const GET_ENTERPRISES_HOT_WORDS = "workbench/GET_ENTERPRISES_HOT_WORDS";
export const GET_PRODUCT_HOT_SEARCH = 'GET_PRODUCT_HOT_SEARCH';
export const LOADING_PRUDCT_MORE = 'workbench/LOADING_PRUDCT_MORE'
export const PRODUCT_PAGE = 'workbench/PRODUCT_PAGE';
export const PRODUCT_LIST = 'workbench/PRODUCT_LIST';
export const GET_SEARCH_PRODUCT_TOTAL_DATA = 'workbench/GET_SEARCH_PRODUCT_TOTAL_DATA';
export const GET_SEARCH_PRODUCT_COUNT = 'workbench/GET_SEARCH_PRODUCT_COUNT';


const initialState = {
  // tabKey: 1, // 首页tab
  data: [],
  tab: 0,
  isBackfill: false,
  listDataID: {
    mid: '',
    isCompany: false,
    isPoject: false,
    childId: ''
  },
  productPage: 1, // 产品page
  workbenchPage: 1,
  isCompany: false,
  isPoject: false,
  childId: '',
  productHasMore: true, // 产品loading
  hasMore: true, // 是否下滑请求数据
  projectsData: {}, // 企业对应的项目
  count: 0,// 数据总数,
  projectsList: [], // 项目数据
  projectsCount: 0, // 查项目总数
  productCount: 0, // 产品总数
  productList: [], // 产品数据
  projectsPage: 1, // 项目页数
  projectsHasMore: true, // 项目loading
  projectHotWords: [], // 查项目热搜
  enterpriseHotWords: [], // 查企业热搜
  productHotWord: [], // 查产品热搜
}



export default (state = initialState, action:any) => {
  switch (action.type) {
    // case TAB_KEY:
    //   return {
    //     ...state,
    //     tabKey: action.tabKey
    //   }
    case GET_SEARCH_PRODUCT_COUNT:
      return {
        ...state,
        productCount: action.productCount
      }
    case PRODUCT_PAGE:
      return {
        ...state,
        productPage: action.productPage
      }
    case LOADING_PRUDCT_MORE:
      return {
        ...state,
        productHasMore: action.productHasMore
      }
    case GET_PRODUCT_HOT_SEARCH:
      return {
        ...state,
        productHotWord: action.productHotWord
      }
    case GET_PROJECT_HOT_WORDS:
      return {
        ...state,
        projectHotWords: action.projectHotWords
      }
    case GET_ENTERPRISES_HOT_WORDS:
      return {
        ...state,
        enterpriseHotWords: action.enterpriseHotWords
      }
    case GET_SEARCH_PROJECT_HAS_MORE:
      return {
        ...state,
        projectsHasMore: action.projectsHasMore
      }
    case GET_SEARCH_PROJECT_PAGE:
      return {
        ...state,
        projectsPage: action.projectsPage
      }
    case GET_SEARCH_PROJECT_COUNT:
      return {
        ...state,
        projectsCount: action.count
      }
    case PRODUCT_LIST:
      return {
        ...state,
        productList: action.productList
      }
    case GET_SEARCH_PRODUCT_TOTAL_DATA:
      return {
        ...state,
        productList: [...state.productList, ...action.data]
      }
    case GET_SEARCH_PROJECT_TOTAL_DATA:
      return {
        ...state,
        projectsList: [...state.projectsList, ...action.data]
      }
    case GET_SEARCH_PROJECT_DATA:
      return {
        ...state,
        projectsList: action.data
      }
    case GET_ENTERPRISES_COUNT:
      return {
        ...state,
        count: action.count
      }
    case GET_ENTERPRISES_PROJECTS:
       return {
        ...state,
        projectsData: {...state.projectsData,...action.projectsData}
       }
    case HAD_MORE:
      return {
        ...state,
        hasMore: action.hasMore
      }
    case SIGNING_DATA:
      return {
        ...state,
        data: [...state.data, ...action.data]
      }
    case WORK_BENCH_PAGE:
      return {
        ...state,
        workbenchPage: action.workbenchPage
      }
     
    case IS_BACKFILL_INITIA:
      return {
        ...state,
        isBackfill: false,
        listDataID: initialState.listDataID
      }

    case PROJECT_DATA:
      return {
        ...state,
        data: action.data
      }
    case TAB_TYPE:
      return {
        ...state,
        tab: action.tab
      }
    case IS_BACKFILL:
      return {
        ...state,
        isBackfill: action.isBackfill,
        listDataID: action.listDataID
      }
    default:
      return state;
  }
}
// 产品总数
export const getProductCount =  (ownProps: any) => {
  return {
    type: GET_SEARCH_PRODUCT_COUNT,
    productCount: ownProps.productCount
  }
}

// 产品数据
export const getProductList = (ownProps: any) => {
  return {
    type: PRODUCT_LIST,
    productList: ownProps.productList || ownProps.data
  }
}


// 产品数据累加
export const getTotalProductList = (ownProps: any) => {
  return {
    type: GET_SEARCH_PRODUCT_TOTAL_DATA,
    data: ownProps.productList
  }
}

// 产品loadiing
export const changeProductHasMore = (ownProps: any) => {
  return {
    type: LOADING_PRUDCT_MORE,
    productHasMore: ownProps.productHasMore
  }
}
// 产品热搜
export const getProductHotWord = (ownProps: any) => {
  return {
    type: GET_PRODUCT_HOT_SEARCH,
    productHotWord: ownProps.productHotWord
  }
}

// 项目热搜
export const getProjectHotWords = (ownProps: any) => {
  return {
    type: GET_PROJECT_HOT_WORDS,
    projectHotWords: ownProps.projectHotWords
  }
}
// 企业热搜
export const getEnterpriseHotWords = (ownProps: any) => {
  return {
    type: GET_ENTERPRISES_HOT_WORDS,
    enterpriseHotWords: ownProps.enterpriseHotWords
  }

}
// 项目loading
export const changProjectsHasMore = (ownProps: any) => {
   return {
    type: GET_SEARCH_PROJECT_HAS_MORE,
    projectsHasMore: ownProps.projectsHasMore

   }
 }
// 项目页数
export const changeProjectsPage = (ownProps: any) => {
  return {
    type: GET_SEARCH_PROJECT_PAGE,
    projectsPage: ownProps.page
  }
}

// 项目count
export const getProjectsCount = (ownProps: any) => {
  return {
    type: GET_SEARCH_PROJECT_COUNT,
    count: ownProps.count
  }
}

// 项目total list
export const getTotalSearchProjects = (ownProps: any) => {
  return {
    type: GET_SEARCH_PROJECT_TOTAL_DATA,
    data: ownProps.data
  }
}

// 项目list
export const getSearchProjects = (ownProps: any) => {
  return {
    type: GET_SEARCH_PROJECT_DATA,
    data: ownProps.data
  }

}

// 企业total list
export const getEnterprisesCount = (ownProps: any) => {
  return {
    type: GET_ENTERPRISES_COUNT,
    count: ownProps.count
  }
}

// 企业list
export const getProjectsData = (ownProps: any) => {
  return {
    type: GET_ENTERPRISES_PROJECTS,
    projectsData: ownProps.data
  }
}

export const changeHasMore = (ownProps: any) => {
  return {
    type: HAD_MORE,
    hasMore: ownProps.hasMore
  }
}
export const signingData = (ownProps: any) => {
  return {
    type: SIGNING_DATA,
    data: ownProps.data
  }
}

export const changeProductPage = (ownProps: any) => {
  return {
    type: PRODUCT_PAGE,
    productPage: ownProps.productPage
  }
}

export const changeWorkBenchPage = (ownProps: any) => {
  return {
    type: WORK_BENCH_PAGE,
    workbenchPage: ownProps.workbenchPage
  }
}

export const changeBackfillInitial = () => {
  return {
    type: IS_BACKFILL_INITIA
  }
}

export const getProjectData = (ownProps: any) => {
  return {
    type: PROJECT_DATA,
    data: ownProps.data,
  }
}



export const changTab = (ownProps: any) => {
  return {
    type: TAB_TYPE,
    tab: ownProps.tab,
  }
}



export const changeBackfillClick = (ownProps: any) => {
  return {
    type: IS_BACKFILL,
    isBackfill: ownProps.isBackfill,
    listDataID: ownProps.listDataID,
    }
}


