/**
* @file actions reducers
* @author wushaobo
*/

import {mapActionTypesToPrefix} from '@/routes/common/actionTypes';

export const actionTypesFactory = mapActionTypesToPrefix({
    GET_SAVE_CODE: null,
    INITALSTATE: null
});

const initialState = {};

export default prefix => (state = initialState, action) => {
    const actionTypes = actionTypesFactory(prefix);
    switch (action.type) {
        case actionTypes.GET_SAVE_CODE:
            return action.codes;
        case actionTypes.INITALSTATE:
            return initialState;
        default:
            return state;
    }
};