import $ from 'jquery';
import _ from 'lodash';
import { Toast } from 'antd-mobile-v5'
import {
  fetchEnterpriseList,
  getEnterprisePage,
  getEnterpriseTotal
} from '@/modules/enterprise/list';
import {
  getEnterpriseRegionData,
  getEnterpriseInfoTypeData,
  getEnterpriseOptRegionData,
  getEnterpriseOptInfoTypeData,
  setIsSearch,
  setSearchBar,
  changeSearchDefineValues
} from '@/modules/enterprise/searchSieve';
import {
  getEnableBackfillState,
  getEnableBackfillType,
  getEnableBackfillKey,
  changeOmitBackfillValues,
  changeDetailsBackfill
} from '@/modules/enterprise/enableBackfill';
import { getQueryVariable, systemUtils } from '@/utils/utils';
import {getProjectData, getProductList} from '@/modules/workbench';
import {listType, titelConfig} from '@/routes/Enterprise/config';
import {
  workbench_company_materials,
  workbench_product_materials
} from '@/routes/config';

const { getPath } = systemUtils;



// 计算地域
export const changeRegionData = (locationData = []) => {
  const data = locationData.reduce((mome, item) => {

    if (mome[item.province]) {
      const children1 = [
        ...mome[item.province].children,
      ]
      if(item.city !== item.province) {
        children1.push({ value: item.city, label: item.name })
      }
      
      mome[item.province].children = children1;
    } else {
      let children = [
        { value: item.province, label: '全部', all: true },
      ]
      if(item.city !== item.province) {
        children.push({ value: item.city, label: item.name })
      }
 
      mome = {
        ...mome,
        [item.province]: {
          value: item.province,
          label: item.provinceName,
          children
        },
      };
    }

    return mome;
  }, {});
  const newData = Object.values(data);

  return newData;
};

let timer =null
// 活到顶部
export const changeScrollTop = (id, ownProps) => {
  if(ownProps.id && ownProps.scrollHeight) {
    const clientHeight =  $('#project-content-box')[0].clientHeight - 1
    timer = setTimeout(() => {
      document.querySelector('#project-content-connent').scrollTo({top: clientHeight})
      clearTimeout(timer)
    }, 100)

  } else {
    document.getElementsByClassName(id)[0].scrollTop = 0
  }

}

// 权限传一级code
export const countRegionCode = (regionData,res ) => {
  let optRegionData = []
  regionData.forEach(item => {
    const children = item.children || []
    if(res[item.value] && res[item.value].length) {
      if(children.length > 1 && children.length - 1 === res[item.value].length) {
        optRegionData.push(item.value)
      } else {
        optRegionData = [...optRegionData, ...res[item.value]]
      }
    }
   
  })
  return optRegionData
}

export const getEnterprisesList = (ownProps, state, rows) => (dispatch) => {
  const type = ownProps.type;
  const { page } = state.enterprise.list[type];
  const list =  state.enterprise.list[type].list


  if (page === 1) {
    dispatch(fetchEnterpriseList({
      data: rows,
      type
    }))
  } else {
    dispatch(fetchEnterpriseList({
      data: [...list, ...rows],
      type
    }))
  }
}

export const getEnterpriseRegionUtil = (ownProps, regionData) => (dispatch) => {
  const type = ownProps.type;
  dispatch(getEnterpriseRegionData({
    type,
    regionData
  }))

}

export const getEnterpriseInfoTypeUtil = (ownProps, infoTypeData) => (dispatch) => {
  const type = ownProps.type;
  dispatch(getEnterpriseInfoTypeData({
    type,
    infoTypeData
  }))
}

export const getEnterpriseOptRegionUtil = (ownProps, optRegionData) => (dispatch) => {
  const type = ownProps.type;
  dispatch(getEnterpriseOptRegionData({
    type,
    optRegionData
  }))

}

export const getEnterpriseOptInfoTypeUtil = (ownProps, optInfoTypeData) => (dispatch) => {
  const type = ownProps.type;
  dispatch(getEnterpriseOptInfoTypeData({
    type,
    optInfoTypeData
  }))

}

export const setIsSearchUtil = (ownProps, isSearch) => (dispatch) => {
  const type = ownProps.type;
  dispatch(setIsSearch({
    type,
    isSearch
  }))

}

export const setSearchBarUtil = (ownProps, searchValues) => (dispatch) => {
  const type = ownProps.type;
  dispatch(setSearchBar({searchValues, type}))
}

export const changeSearchDefineUtil = (ownProps, searchDefineValues) => (dispatch) => {
  const type = ownProps.type;
  dispatch(changeSearchDefineValues({searchDefineValues, type}))
}

export const changeEnterprisePageUtil = (ownProps, page) => (dispatch) => {
  const type = ownProps.type;
  dispatch(getEnterprisePage({ page, type }));
}

export const changeEnterpriseTotalUtil = (ownProps, total) => (dispatch) => {
  const type = ownProps.type;
  dispatch(getEnterpriseTotal({total, type}))
}

export const getEnableBackfillUtil = (ownProps, scrollTop) => (dispatch) => {
  const key = ownProps.key;
  dispatch(getEnableBackfillState({
    key,
    scrollTop
  }))

}

export const fetchEnablebackfillUtil = (ownProps, state, id) => (dispatch) => {
  const type = state.enterprise.enableBackfill.type || getQueryVariable('type') || listType.INFORMATION.value;
  const key = state.enterprise.enableBackfill.key
  const className = titelConfig[type].className;
  const scrollTop = document.getElementsByClassName(className)[0].scrollTop;
  getEnableBackfillUtil({...ownProps, key}, scrollTop)(dispatch)
  changeDetailsBackfillGrey(state, id)(dispatch)
}

export const fetchInitialStateStatus = () => (dispatch) => {
  const entId = getQueryVariable('entId');
  const type = getQueryVariable('type') || listType.INFORMATION.value;
  const key = `${type}-${entId}`
  dispatch(getEnableBackfillType({type}))
  dispatch(getEnableBackfillKey({key}))

}

export const clearEnablebackfillKeyUtil =  (ownProps, state, id) => (dispatch) => {
  const entId = getQueryVariable('entId');
  const type = getQueryVariable('type') || listType.INFORMATION.value;
  const key = `${type}-${entId}`
  const backfillValues = state.enterprise.enableBackfill.backfillValues;
  const omitbackfillValues =  _.omit(backfillValues, key)
  dispatch(changeOmitBackfillValues({
    backfillValues: omitbackfillValues
  }))
}

export const changeHasCollectedEnterpriseUtil = (state, ownProps) => {
  const config = {
    [workbench_company_materials]: {
      list: state.workbench.data,
      changeProjectDetailsState: getProjectData
    },
    [workbench_product_materials]: {
      list: state.workbench.productList,
      changeProjectDetailsState: getProductList
    }

  }
  return config[ownProps.materials]
}

export const fetchCollectEnterpriseList = (ownProps, state) => (dispatch) => {
  const {
    isDelCollect,
    entId,
    materials
} = ownProps

const {
  list,
  changeProjectDetailsState
} = changeHasCollectedEnterpriseUtil(state, {...ownProps, materials})

    const data = list.map((item) => {
        if (item.entId === entId) {
            if (isDelCollect) {
                item.hasCollected = true;
              } else {
                item.hasCollected = false;
              }
            }
           return item;
        });
        dispatch(changeProjectDetailsState({
          data: _.cloneDeep(data),
          type: materials
       }))
}

 // 进入地图
 export const showMapUrl = (entRegLal) => (history) =>{
  if(!entRegLal) {
    Toast.show('暂无数据')
  }
  const arr = entRegLal.split(',')
  const longitude = Number(arr[1])
  const latitude = Number(arr[0])
  history.push(getPath(`/AMap?longitude=${longitude}&latitude=${latitude}`))
}

// 保留置灰
export const changeDetailsBackfillGrey = (state, mid) => (dispatch) => {
    if(mid === null) {
        dispatch(changeDetailsBackfill({
            details: state
        }))
    } else {
        const details = state.enterprise.enableBackfill.details;
        const mergeDetails = [...details, mid];
        dispatch(changeDetailsBackfill({
          details: mergeDetails
        }))
        localStorage.setItem('detailsMid', mergeDetails)
    }
     
}
// 刷新页面置灰回填
export const getDetailsBackfillGrey = () => (dispatch) => {
  const detailsMid = localStorage.getItem('detailsMid')
  const details = detailsMid ? _.split(detailsMid, ',') : []
  changeDetailsBackfillGrey(details, null)(dispatch)

}

// 正文中...
export const changeTextLight = (data) => {
   const rows = data.map(item => {
    if(item.textKeyWord && item.textKeyWord .length) {
      let textKeyWord = _.join(item.textKeyWord , ' ');
      textKeyWord = textKeyWord.length > 6 ? `${textKeyWord.slice(0, 5)}...` : textKeyWord
      item.bidProjectTitle = `${item.bidProjectTitle}(${textKeyWord}在正文中)`
    }
      return item
    })
  return rows
}
   
