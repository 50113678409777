export const PARTNERS_SHIP = 'enterprise/PARTNERS_SHIP';

const initialState = {
    partnership: 1
}

export default (state, action) => {
    state = state || initialState;
    if (action.type === PARTNERS_SHIP) {
        return {
            ...state,
            partnership: action.partnership
        }
    } else {
        return state;
    }
}

export const changePartnerShip = (ownProps) => {
    return {
        type: PARTNERS_SHIP,
        partnership: ownProps.partnership
    }

}

