import Enum from 'enum';
import winImg from '../../images/entDetails/win.png';
import proxyImg from '../../images/entDetails/proxy.png';
import rivalImg from '../../images/entDetails/rival.png';
import ruEntImg from '../../images/entDetails/ruEnt.png';


export const types = new Enum({
    'OWNER': 0, // 业主
    'SUPPLIER': 1, // 供应商
    'AGENT': 3 // 代理机构
})

export const partnerEnum = new Enum({
    'COOPERATION': 1, //'合作供应商'
    'WON': 2, //入围供应商
    'AGENT': 3, // 代理机构
    'OWNER1': 4, // 业主1
    'COMPETE': 5, // 竞争对手
    'OWNER2': 6, // 业主2
})

export const listType = new Enum({
    'INFORMATION': 'INFORMATION', // 标讯更多
    'COOPERATION': 'COOPERATION', // 合作企业
    'DETAILS': 'DETAILS', // 关联项目
    'TA': 'TA' // ta负责项目
})

export const testData = {
    partnerEntTotal: 1001,
    ruEntTotal: 15,
    proxyEntTotal: 120,
    rivalEntTotal: 6,
};
export const selectOptions = {
    contactType: [ // 联系方式
        { value: '1', label: '手机' },
        { value: '2', label: '固话' },
        { value: '3', label: '邮箱' }
    ],
    roleType: [ // 联系人身份
        { value: '', label: '全部联系人' },
        { value: '0', label: '招标联系人' },
        { value: '1', label: '中标联系人' },
        { value: '3', label: '代理联系人' }
    ],
    sortType: [
        { value: '0', label: '智能排序' },
        { value: '1', label: '按负责项目时间排序' },
        { value: '2', label: '按负责项目次数排序' }
    ],
    sourceType: [
        { value: '', label: '全部' },
        { value: 'ai', label: '网络' },
        { value: 'fixedLine', label: '推广' },
        { value: 'email', label: '介绍' }
    ]
}


export const tableConfig = {
    [types.OWNER.value]: [
        {key: 'count1', name: '合作供应商', icon: winImg, type: '1'},
        {key: 'count2', name: '入围供应商', icon: ruEntImg, type: '2'},
        {key: 'count3', name: '合作代理机构', icon: proxyImg, type: '3'}
    ],
    [types.SUPPLIER.value]:  [
        {key: 'count1', name: '合作业主', icon: winImg, type: '4'},
        {key: 'count2', name: '竞争对手', icon: rivalImg, type: '5'},
        // {key: 'count3', name: '入围企业', icon: ruEntImg, type: '7'}
    ],
    [types.AGENT.value]: [
        {key: 'count1', name: '合作业主', icon: proxyImg, type: '6'}
    ]
};


export const exhibitionClerk = {
    [types.OWNER.value]: {
        [partnerEnum.COOPERATION.value]: {
            frequency: '累计中标次数',
            latelyTime: '最近中标时间',
            title: '合作供应商'
        },
        [partnerEnum.WON.value]: {
            frequency: '累计入围次数',
            latelyTime: '最近入围时间',
            title: '入围供应商'
        },
        [partnerEnum.AGENT.value]: {
            frequency: '累计代理次数',
            latelyTime: '最近代理时间',
            title: '合作代理机构'
        }  
    },
    [types.SUPPLIER.value]: {
        [partnerEnum.OWNER1.value]: {
            frequency: '累计合作次数',
            latelyTime: '最近合作时间',
            title: '合作业主'
        },
        [partnerEnum.COMPETE.value]: {
            frequency: '累计竞争次数',
            latelyTime: '最近竞争时间',
            title: ''
        }
       
    },
    [types.AGENT.value]: {
        [partnerEnum.OWNER2.value]: {
            frequency: '累计合作次数',
            latelyTime: '最近合作时间',
            title: '合作业主'
        }
       
    }
}

export const titelConfig = {
    [listType.INFORMATION.value]: {
        placeholder: '请输入标讯名称、项目编号、关键词',
        title: '标讯列表',
        count: '项目',
        className: 'enterprise-content-list',
        text: '条'
    },
    [listType.COOPERATION.value]: {
        placeholder: '请输入企业名称',
        title: '合作企业',
        count: '企业',
        className: 'enterprise-content-list',
        text: '家'
    },
    [listType.DETAILS.value]: {
        placeholder: '请输入项目关键词',
        title: '详情',
        count: '项目',
        className: 'project-content',
        text: '条'
    },
    [listType.TA.value]:{
        placeholder: '请输入关键字，多个关键字用空格隔开',
        title: 'Ta负责的项目',
        count: '项目',
        className: 'enterprise-content-list',
        text: '条'
    }
}


export const config = [
    {
        name: '业主',
        key: types.OWNER.value,
        isDisable: false
    }, {
        name: '供应商',
        key: types.SUPPLIER.value,
        isDisable: false 
    }, {
        name: '代理机构',
        key: types.AGENT.value,
        isDisable: false 
    }
]

export const partnerTypeMap = {
    [types.OWNER.value]: [
        {label: '合作供应商', value: partnerEnum.COOPERATION.value},
        {label: '入围供应商', value: partnerEnum.WON.value},
        {label: '合作代理机构', value: partnerEnum.AGENT.value}
    ],
    [types.SUPPLIER.value ]: [
        {label: '合作业主', value: partnerEnum.OWNER1.value},
        {label: '竞争对手', value: partnerEnum.COMPETE.value},
        // {label: '入围供应商', value: 7} // 供应商 下入围参数需要单独判断
    ],
    [types.AGENT.value]: [
        {label: '合作业主', value: partnerEnum.OWNER2.value}
    ]
  }

export const tagColors = [
    {color: '#F79435', background: '#FEF1DF'},
    {color: '#6E8EFC', background: '#DFECFF'},
    {color: '#50B72A ', background: '#E8F7E3'},
    {color: '#4387EA', background: '#DFECFF'},
    {color: '#F79435', background: '#FEF1DF'},
    {color: '#6E8EFC', background: '#DFECFF'},
    {color: '#50B72A', background: '#E8F7E3'},
    {color: '#4387EA', background: '#DFECFF'}
 ];

 export const tagStyleMap = {
    '决策人': {background: '#FFF1E2', color: '#FF8411', borderRadius: '11px'},
    '关键人': {background: '#FFEBE5', color: '#FE774C', borderRadius: '11px'},
    '推荐': {background: '#E8F7E3', color: '#50B72A', borderRadius: '11px'},

    '招标联系人': {background: '#ECF0FF', color: '#4B74FE'},
    '代理联系人': {background: '#FEF1DF', color: '#FF8411'},
    '中标联系人': {background: '#FEE9E9', color: '#F62828'},

    // 剩下的职务统一蓝色就行
    '法人': {background: '#FFF1E2', color: '#FF8411'},
    '技术专家': {background: '#ECF0FF', color: '#4B74FE'},

    '疑似kp': {background: '#FFF1E2', color: '#FF8411'},
    '近期收录': {background: '#ECF0FF', color: '#4B74FE'},
    '疑似代理记账': {background: '#FEE9E9', color: '#F62828'},
    '疑似空号': {background: '#F1F2F6', color: '#7C8294'}
};
 export const keywordMap = [
    {
        value: '0',
        label: '招标'
    }, {
        value: '1',
        label: '中标' 
    },  {
        value: '3',
        label: '代理' 
    }
 ]

 export const timeConfig = [{
    text: '全部时间',
    value: 'UNLIMITED'
 }, {
    text: '近1个月',
    value: 'LASTONEMONTH',
 }, {
    text: '近3个月',
    value: 'LASTTHREEMONTH',
 }, {
    text: '近半年',
    value: 'LASTHALFYEAR',
 }, {
    text: '近一年',
    value: 'LASTONEYEAR',
 }]
