/**
 * @file 页面: 地域选择器-方法
 * @author wushoaobo<wushoaobo@qike366.com>
 * @date 2021-10-14
 */

import { axios } from 'routes/utils';
import { getCodeRegion, getRegionDataEntries } from '@/modules/provinces';

let result = '';
// 查找地域name
export const getRegionUtil = (tree, tag) => {
    const tableTree = tree || []
    for (let index = 0; index < tableTree.length; index++) {
        const element = tableTree[index];

        if (element.children && element.children.length) {
            getRegionUtil(element.children, tag)
        } else {
            if (element.code === tag) {
                result = element.newName || element.label
            }
        }

    }
    return result

}

export const getReginValues = (value, regionData) => {
    if (value[0] === '0') {
        return {}
    }
    if (value[0] === value[1]) {
        let job = {}
        regionData.forEach((item) => {
            if (item.code === value[0]) {
                const children = item.children.map(subItem => subItem.code);
                job[item.code] = children

            }
        })
        return job

    }
    return {
        [value[0]]: value[1]
    }

}

// 地区数据
export const getRegionState = () => (dispatch) => {
    const regionLocalList = localStorage.getItem('regionData-toal') ? JSON.parse(localStorage.getItem('regionData-toal')) : null
    const regionDataEntriesStorage = localStorage.getItem('regionDataEntries') ? JSON.parse(localStorage.getItem('regionDataEntries')) : null

    if(regionDataEntriesStorage) {
        dispatch(getRegionDataEntries({
            regionDataEntries: regionDataEntriesStorage
        }))
    }

    if (regionLocalList) {
        dispatch(getCodeRegion({
            regionData: regionLocalList
        }))
        return Promise.resolve(regionLocalList)
    }
    return axios.get('/publics/code/region').then(res => {
        if (+res.code === 200) {
            const regionData = res.data;
            regionData.unshift({
                code: "0",
                level: "1",
                name: "全国",
                parent: "",
                children: []

            })
            regionData.forEach(item => {
                item.value = item.code
                item.key = item.code
                item.label = item.name
                const children = item.children;

                // 将直辖市的区改成level:2

                if (children[0]?.level === '3') {
                    children.forEach(childrenItem => {
                        childrenItem.level = '2'
                    })
                }

                // children && children.unshift({
                //     children: [],
                //     code: item.code,
                //     label: item.name,
                //     level: "1",
                //     name: '全部',
                //     parent: "",
                //     value: item.code,
                //     newName: item.name,
                //     all: true
                // })
                children.forEach(subItem => {
                    subItem.value = subItem.code
                    // subItem.key = subItem.code
                    subItem.label = subItem.name
                    // subItem.children = subItem.children

                    // if (subItem?.children?.length) {
                    //     subItem.children.forEach(lastItem => {
                    //         lastItem.value = lastItem.code
                    //         lastItem.key = lastItem.code
                    //         lastItem.label = lastItem.name
                    //         lastItem.children = lastItem.children
                    //     })
                    // }
                })

            })
            const regionDataEntries = regionData.reduce((mome, item) => {
                const code = item.code
                const children = item.children || []
                const subItem = [];
                children.forEach((suitem: any) => {
                    if (suitem.code !== code || children.length === 1) {
                        subItem.push(suitem.code)
                    }
                })
                mome = { ...mome, [code]: subItem }
                return mome
            }, {})

            // 数组存储到redux中, 全局都在在用地域组件, 避免重复请求接口数据
            dispatch(getCodeRegion({
                regionData
            }))
            dispatch(getRegionDataEntries({
                regionDataEntries
            }))



            localStorage.setItem('regionData-toal', JSON.stringify(regionData))
            localStorage.setItem('regionDataEntries',  JSON.stringify(regionDataEntries))

            return regionData
        }
    })

}