export const tabConfig = {
  "PROJECT": 0,
  "ENTERPRISE": 1,
  'CHANNEL': 2,
  'AGENT': 3,
}

export const tabsLinkConfig = [
  {
      [tabConfig.PROJECT]: "查标讯",
      id: tabConfig.PROJECT
  }, {
      [tabConfig.ENTERPRISE]: "查业主", //企业
      id: tabConfig.ENTERPRISE
  }, {
      [tabConfig.CHANNEL]: '查渠道',
       id: tabConfig.CHANNEL
  },
  {
      [tabConfig.AGENT]: '查代理',
       id: tabConfig.AGENT
  }
]
export const groupTabsLinkConfig = [
  {
      [tabConfig.PROJECT]: "标讯信息",
      id: tabConfig.PROJECT
  }, {
      [tabConfig.ENTERPRISE]: "集团企业", //企业
      id: tabConfig.ENTERPRISE
  }, {
      [tabConfig.CHANNEL]: '集团供应商',
       id: tabConfig.CHANNEL
  }
]

// 企业搜索input 左侧 选择名称
export const inputTab = {
  enterprise: "2", // 企业名称
  product: "1" // 产品名称
}

export const placeholderConfig = {
  [tabConfig.PROJECT]: '请输入项目名称、编号或关键词',
  [tabConfig.ENTERPRISE]: '请输入产品关键词',
  [tabConfig.CHANNEL]: '请输入产品关键词',
  [tabConfig.AGENT]: '请输入产品关键词',
}


export const searchTypeApi1 = "/projects/search/conditions?searchType=1";
export const  searchTypeApi2 = "/projects/search/conditions?searchType=2";

//产品企业身份对应时间
export const timeTyptConfig = {
  0: '采购',
  1: '中标',
  3: '代理'
}

export const fastEntry = [
  { id: 0, title: '业主专区', icon: 'yezhuzhuanqu' },
  { id: 1, title: '行业专区', icon: 'hangyezhuanqu' },
  // { id: 2, title: '集团采购', icon: 'qiyejiankong' },
  { id: 2, title: '企业监测', icon: 'qiyejiankong' },
  { id: 3, title: '我的收藏', icon: 'wodeshoucang' }
]