/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
import { createFromIconfontCN } from '@ant-design/icons';
import moment from 'moment';
import { runtimeConfig } from '../config';
import { getCookie } from 'routes/utils';

export const isUrl = (path: string): boolean => reg.test(path);

//获取url链接中的参数
export function getQueryVariable(variable: string) {
  if (typeof window === 'undefined') {
    return null;
  }

  const url = window.location.search;
  const reg = new RegExp(`(^|&)${variable}=([^&]*)(&|$)`);
  const result = url.slice(1).match(reg);
  return result ? decodeURIComponent(result[2]) : null;
}

export function formatDate(value: any) {
  if (typeof value == 'undefined') {
    return '';
  } else {
    const date = new Date(parseInt(value));
    const y = date.getFullYear();
    let MM = date.getMonth() + 1;
    let d = date.getDate();

    MM = MM < 10 ? `0${MM}` : MM;
    d = d < 10 ? `0${d}` : d;
    return `${y}-${MM}-${d}`;
  }
}
export function formatDateTime(value: any) {
  if (typeof value == 'undefined') {
    return '';
  } else {
    const date = new Date(parseInt(value));
    const y = date.getFullYear();
    let MM = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();

    MM = MM < 10 ? `0${MM}` : MM;
    d = d < 10 ? `0${d}` : d;
    h = h < 10 ? `0${h}` : h;
    m = m < 10 ? `0${m}` : m;
    s = s < 10 ? `0${s}` : s;
    return `${y}-${MM}-${d} ${h}:${m}:${s}`;
  }
}

export const IconFont = createFromIconfontCN({
  scriptUrl: ['//at.alicdn.com/t/font_2798674_0mwvhzhphvce.js'],
});

export const checkTel = (num: any) => {
  const isPhone = /^1[3|4|5|7|6|8|9]\d{9}$/;
  const isMob = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
  const value = num.trim();
  if (isMob.test(value) || isPhone.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const trim = (str: any) => {
  return str && str.replace(/\s/g, '');
};

//距离现在时间差
export const getDayMinus = (packageDate: any) => {
  if (!packageDate) {
    return '';
  }
  packageDate = packageDate.substr(0, 10);
  const date = new Date();
  const seperator1 = '-';
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  let strDate: any = date.getDate();
  if (month >= 1 && month <= 9) {
    month = `0${month}`;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = `0${strDate}`;
  }
  const currentDay: any = new Date(year + seperator1 + month + seperator1 + strDate);
  const lastDay: any = new Date(packageDate);
  return (lastDay - currentDay) / (1000 * 60 * 60 * 24) + 1;
};

export const systemUtils = {
  getPath() {
    let pathName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    if (/^\/(.+)/.test(pathName)) {
      pathName = pathName.replace(/^\/(.+)/, '$1');
    }

    return runtimeConfig.NODE_ENV === 'production' && !/^\/$/.test(runtimeConfig.PUBLIC_PATH)
      ? runtimeConfig.RAZZLE_SERVER_ON === 'true'
        ? `/${pathName}`
        : runtimeConfig.PUBLIC_PATH + pathName
      : `/${pathName}`;
  },
  getUri() {
    const requestUri = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    return runtimeConfig.NODE_ENV === 'production' && !/^\/$/.test(runtimeConfig.PUBLIC_PATH)
      ? runtimeConfig.RAZZLE_SERVER_ON === 'true'
        ? requestUri
        : runtimeConfig.PUBLIC_PATH.substr(0, runtimeConfig.PUBLIC_PATH.length - 1) + requestUri
      : requestUri;
  },
};

function rand16Num(len = 0) {
  const result = [];
  for (let i = 0; i < len; i++) {
    result.push('0123456789abcdef'.charAt(Math.floor(Math.random() * 16)));
  }
  return result.join('');
}

/**
 * 生成一个全局唯一的guid，且格式符合guid规范
 * GUID 的格式为“xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx”
 * 其中每个 x 是 0-9 或 a-f 范围内的一个32位十六进制数
 * 第四版的GUID使用了新的算法，其产生的数字是一个伪随机数。
 * 它生成的GUID的第三组数字的第一位是4
 *
 * @return {string} 符合guid格式的字符串
 */
export function guid() {
  const curr = new Date().valueOf().toString();
  return ['4b534c47', rand16Num(4), `4${rand16Num(3)}`, rand16Num(4), curr.substring(0, 12)].join(
    '-'
  );
}

/**
 * 很少变更的数据做localstorage缓存，比如城市编码、行业分类等
 * loadStaticData - 写入及获取缓存数据
 * @author xudeming
 * @date 2022-04-14
 * @param  { String } key 缓存的key
 * @param  { Object } val 缓存的数据
 * @return { Object } 返回的数据
 *
 * demo:
 * const city = {
      version: 1.0,
      content: {

      }
    }
    loadStaticData('city', city);
 */
export function loadStaticData(key: string, val: object = {}) {
  try {
    // 本地存在并且版本一致，则返回本地的数据；否则返回false
    if (typeof window === 'undefined') {
      return false;
    }
    const localVal = JSON.parse(localStorage[key] || '{}');

    if (localStorage[key] && localVal.version === val.version) {
      return localVal.content;
    } else {
      localStorage[key] = JSON.stringify(val.content || {});
    }
    return false;
  } catch (err) {
    console.error(err);
    return {};
  }
}

// 金额处理
export const formatMoneyY = (number: string, tofix = 4) => {
  // money: 传入的数字 ，decimal: 希望返回小数点几位
  const formatMoney = (money, decimal = 2, isPoint = true) => {
    decimal = decimal >= 0 && decimal <= 20 ? decimal : 2;

    money = parseFloat(((money + '').replace(/[^\d\.-]/g, '') - 0).toFixed(decimal)) + '';
    var l = money.split('.')[0].split('').reverse(),
      r = money.split('.')[1],
      t = '';
    if (isPoint) {
      for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
      }
    } else {
      for (let i = 0; i < l.length; i++) {
        t += l[i];
      }
    }
    return t.split('').reverse().join('') + (r ? '.' + r : '');
  };

  const numList = number ? (number + '').split('.') : [];
  let newNum = Number(number);
  if (numList.length > 1) {
    newNum = (newNum * 1000000 * 10000) / 1000000;
  } else {
    newNum = newNum * 10000;
  }

  if (number === '0.0' || number === '0') {
    return '-';
  } else {
    if (newNum < 10000) {
      return `${newNum}元`;
    }
    if (10000 <= newNum && newNum < 100000000) {
      return `${formatMoney(newNum / 10000, tofix, false)}万`;
    }
    if (newNum >= 100000000) {
      return `${formatMoney(newNum / 100000000, tofix, false)}亿`;
    }
  }
};

// 大数字格式化
export const formatBigMoneyNum = (money: number) => {
  if (money < 10000) {
    return money;
  }
  if (money >= 10000 && money < 100000) {
    return `${Math.floor(money / 10000)}万+`;
  }
  if (money >= 100000) {
    return `${Math.floor(money / 100000)}0万+`;
  }
};
export const isChsme = () => {
  return getCookie('appcode') === 'bidjj' || localStorage.getItem('appcode') === 'bidjj';
};
/**魔方 */
export const isOem = () => {
  if (window?.JSNativeInterface) {
    const _userInfo = JSNativeInterface?.getUserInfo() || '';
    const userInfo = _userInfo !== '' ? JSON.parse(_userInfo) : {};
    return userInfo?.QKSolutionCode === 'third_oem_1';
  } else {
    return localStorage.getItem('QKSolutionCode') === 'third_oem_1';
    return false;
  }
};
export const isOpenscrm = () => {
  if (window?.JSNativeInterface) {
    const _userInfo = JSNativeInterface?.getUserInfo() || '';
    const userInfo = _userInfo !== '' ? JSON.parse(_userInfo) : {};
    const openscrm = userInfo.QKOpenScrm;
    return openscrm;
  } else {
    return localStorage.getItem('QKOpenScrm') !== 'false';
    return true;
  }
};
export const getDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS';
  } else if (userAgent.match(/Android/i)) {
    return 'Android';
  } else {
    return 'unknown';
  }
};
export const isIos = () => {
  return getDeviceType() === 'iOS';
};
export const sendMessageToNative = (actionName, params) => {
  return new Promise((resolve, reject) => {
    // 将回调函数挂载到window对象上，以便原生代码可以调用
    window[actionName] = (response) => {
      resolve(response); // 注意可能要格式转换，根据实际情况
      // 移除挂载的回调函数，避免内存泄露
      delete window[actionName];
    };
    // 发送消息给原生代码
    window.webkit?.messageHandlers[actionName].postMessage(params);
  });
};
