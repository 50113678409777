/**
 * @file 页面: 公共方法
 * @author wushoaobo<wushoaobo@qike366.com>
 * @date 2021-10-19
 */
import { Toast } from 'antd-mobile';
import _ from 'lodash';
import { axios, getCookie } from 'routes/utils';
import { powerConfig, titleConfig } from '@/routes/SubscribeCenter/config'
import {getTypeValues, changeIsAllRegionCode} from '@/routes/common/util';


export function getSearchInfoTypes() {
    const code = editionPackage()
    return axios.get('/publics/code/getInfoTypesLevel').then(res => {
        if (+res.code === 200) {
            const data = res.data || []
            let newData = [{
                id: 'all', name: '全部'
            }]
            data.forEach((item => {
                newData.push({
                    id: item.value,
                    name: item.label
                })
            }));
            // // 一期把拟在建过滤掉
            if (code === powerConfig.TRIAL || code === powerConfig.STANDARD) {
                newData = newData.filter(item => item.id !== "PROPOSE")
            }

            return newData
        }


    })
}


// 获取订阅监控分组类表
export function getNewSubscribeData(isGroupSubscribe, getSubData) {
    const url = !isGroupSubscribe ? '/monitor/group/page' : '/subscribes/page';
    return axios.get(url, {
        params: {
            page: '1',
            perPage: '50'
        }
    }).then(res => {
        const data = !isGroupSubscribe ? (res.data.rows || []) : res.data.rows;
        const subData = data.reduce((mome, item) => {
            mome = { ...mome, [item.id]: item }
            return mome
        }, {})
        // 将所有订阅器信息存起来{editId:{订阅器1}, editId: {订阅器2}}
        getSubData && getSubData({ subData })
        return { res, subData }
    }).catch(err => {
        return err
    })
}

export function getProvincesUtil(callBack) {
    if (location.pathname === '/UserCenter/PrivilegeDeatils/') return
    const regionLocalList = localStorage.getItem('provincesData') ? JSON.parse(localStorage.getItem('provincesData')) : null
    if (regionLocalList) {
        callBack && callBack({
            provincesData: regionLocalList
        })
        return Promise.resolve(regionLocalList)

    }
    return axios.get('/publics/code/region').then(res => {
        if (+res.code === 200) {
            const data = res.data.map(item => {
                item.id = item.code;
                return item
            })
            data.unshift({ id: 'all', name: '全国' })
            // 回调 dispatch 地域接口
            callBack && callBack({
                provincesData: data
            })
            localStorage.setItem('provincesData', JSON.stringify(data))

            return data

        }

    })
}


const arrToTree = (arr,isSingle=false) => {
    arr=JSON.parse(JSON.stringify(arr))
    let data=[]
    arr.forEach(item =>{
        item.children = arr.filter(v => v.parent === item.code);
        if (!isSingle) {
            item.children.unshift({
              code: item.code,
              label: '全部',
              level: '1',
              name: '全部',
              parent: '',
              value: item.code,
              newName: item.name,
              all: true,
            });
        }      
      
        //  找父节点
        arr.filter(v => item.parent === v.code ).length===0?data.push(item):''
        
    })
    return data
};


// 获取行业数据，并存到redux中 
export function getParentUtils(callBack) {
    if (location.pathname === '/UserCenter/PrivilegeDeatils/') return
    return axios.get('/publics/code/nic-data').then(res => {
      if (+(res.code) === 200) {
          const data = res.data.map(item => {
              item.id = item.code;
              item.value = item.code;
              item.label = item.name;
              return item
          })
          const nicData = arrToTree(data,true);
          // 回调 dispatch 行业接口
          callBack && callBack({
              nicData
          })
          return nicData
      }
    })
}


// export function getParentUtils(callBack) {
//     if (location.pathname === '/UserCenter/PrivilegeDeatils/') return
//     return axios.get('/publics/code/nic').then(res => {
//         if (+res.code === 200) {
//             const data = res.data.map(item => {
//                 item.id = item.code;
//                 return item
//             })
//             data.unshift({ id: 'all', name: '全部' });


//             // 回调 dispatch 行业接口
//             callBack && callBack({
//                 nicData: data
//             })
//             return data
//         }
//     })
// }

// 获取业主数据，并存到redux中    TODO: 用的假数据，记得改过来
export function getOwnerUtils(callBack) {
  if (location.pathname === '/UserCenter/PrivilegeDeatils/') return
  return axios.get('/publics/code/nic-owner').then(res => {
    if (+res.code === 200) {
      const data = res.data.map(item => {
        item.id = item.code;
        item.value = item.code;
        item.label = item.name;
        return item
      })
      const nicOwner = arrToTree(data);
      // 回调 dispatch 行业接口
      callBack && callBack({
          nicOwner
      })
      return data
    }
  })
}

// 检测时空字符串还是空输入格
export function checkIsNull(str) {
    if (str == "") return true;
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    return re.test(str);

}

// 校验订阅管理保存数据
export function checkSubscribe(params, isGroupSubscribe) {
    const {
        name, // 订阅名称
        // toalIndustry, // 所在行业
        keywordsData, // 关键词
        countArry // 几项关键词
    } = params
    let isCheck = false;
    // if (!(Object.keys(toalIndustry || {}).length)) {
    //     Toast.info('请选择所在行业', 1)
    //     isCheck = true
    // }

    // 监控关键词不做必填校验 //isGroupSubscribe &&
    if ( countArry.length === 1 && !keywordsData[0].length) {
        Toast.info('请添加关键词', 1)
        isCheck = true
    }
    // 订阅关键词分组必填校验（监控用不到） //isGroupSubscribe &&
    if ( countArry.length === 2) {
        if (!keywordsData[0].length || (keywordsData[1] && !keywordsData[1].length)) {
            Toast.info('请添加关键词', 1)
            isCheck = true
        }


    }
    // 检测时空字符串还是空输入格
    if (checkIsNull(name)) {
        const _name = isGroupSubscribe ? '订阅器' : '监测分组'
        isCheck = true
        Toast.info(`请填写${_name}名称`, 1)
    }
    return isCheck;

}

// 请求搜索列表数据
export const getSubscribeSearch = (setPageLoading, params = {}, callback = '', regionData = []) => {
  const { page = 1, id, regions = [] } = params;
  let url = `/subscribes/search?page=${page}&perPage=20`
  if(typeof id === 'undefined') return
  const isAllRegionCode = changeIsAllRegionCode(regions, regionData, 'region')
  if(isAllRegionCode) {
      params.regions = []
  }

  setPageLoading&& setPageLoading({loading: true});
  axios.post(url, params).then(res => {
    if (+res.code === 200) {
      const data = res.data.rows;
      callback && callback({
          subscribeSearchData: data,
          subscribePage: res.data.page,
          subscribeCount: res.data.count,
          hasMore: true
      })
    }
  }).finally(() => {
    setPageLoading&&  setPageLoading({loading: false});
  })
}

// 获取年月日
export const getTimeDate = () => {
    const myDate = new Date();
    const year = myDate.getFullYear();
    const month = myDate.getMonth() + 1;
    const date = myDate.getDate();
    return `${year}-${month}-${date}`

}

// 超过字数...
export const changeFontNum = (text, num = 10) => {
    if (text.length < num) {
        return text
    }
    const newTest = text.slice(0, num)
    return `${newTest}...`
}

// 版本套餐

export const editionPackage = code => {
    const suiteCode = getCookie('suite') && getCookie('suite').suiteCode;
    return suiteCode;

}
export const getNavigatorIos = () => {
    return navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)

}

// 切换条件 回到顶部
export const getScrollTo = (id) => {
    document.getElementById(id).scrollTop = 0;
}

//指定某个元素的位置
export const getscrollIntoView = (id) => {
    id && document.getElementById(id) && document.getElementById(id).scrollIntoView()
}
// 
export const changeIntegrationData= (regionData = {}, data = {}) => {
    let list = []
    if(!Object.values(regionData).length) {
    return data
    }
    if(Array.isArray(data)) {
        data.forEach(item => {
            if(regionData[item]) {
                list = [...list, ...regionData[item]]
            } else {
                list = [...list, item]
            }
           
        })

    } else {
        for(let key in data) {
            const values = data[key];
            const totalValues = regionData[key];
            if(totalValues.length === values.length) {
                list.push(key)
            } else {
                list = [...list, ...values]
            }
        }
    }
 
    return list

}

export const changeRegionValues = (regions) => {
    const values = Object.values(regions).filter(item => Array.isArray(item));
    if (values.length) {
        const momoes = values.reduce((momoe, item) => {
            momoe = [...momoe, ...item]
            return momoe
        }, [])
        return momoes
    }
    return []
}


export const getFormatCodeMap = (allData, selectVals) => {
    let codeMap = {}
    allData.forEach(parentItem => {
        parentItem?.children?.forEach(childrenItem => {
            if (selectVals.includes(childrenItem.code)) {
                codeMap[parentItem.code] ? 
                    codeMap[parentItem.code].push(childrenItem.code):
                    codeMap[parentItem.code]=[childrenItem.code]
            }
        })
    })
    return codeMap
}




export const getRegionKeys = (regions) => {
    const keys = Object.keys(regions);
    const type = keys.join('');
    return type
}

export const getScreenType = (newVlaues, types) => {
    let selectedValue = {}
    for (let key in types) {
        const values = types[key];
        newVlaues.forEach((item) => {
            if (values.indexOf(item) > -1) {
                if (selectedValue[key]) {
                    selectedValue[key].push(item)
                } else {
                    selectedValue[key] = [item]
                }
            }
        })
    }
    return selectedValue
}



// 获取title
export const countHandleTitle = (values = {}, types = [], modal = "") => {
    const title = titleConfig[modal] ? titleConfig[modal].name : '全部类型'

    const newValues = _.cloneDeep(values);
        for (let key in newValues) {
        const list = newValues[key];
        const type = list.indexOf(key)
        if(type !== -1 && list.length !==1) {
            list.splice(type, 1)
        }
    }
    const typeValues = Object.values(newValues)
    const newKeys = _.flattenDeep(typeValues)
    let newTypes = []

    for(let key in types) {
        const typsValues = types[key];
        if(typsValues.indexOf(key) > -1) {
           const editValues = newValues[key] || []
            newTypes = [...newTypes, ...editValues]
        } else {
            newTypes = [...newTypes, ...typsValues]
        }


    }
    // const newTypes = _.flattenDeep(Object.values(types))

    const size = _.size(types)

    if (newKeys.length === newTypes.length || !newTypes.length) {
        return { title, isAll: true }
    }
    return { title: `已选${size}项`, isAll: false }
}

// 兼容之前数据, 通过一级获取二级code
export const changeDditData = (screenTypeValues, editData = []) => {
    let list = [];
    editData.forEach(item => {
        if (screenTypeValues[item]) {
            const types = screenTypeValues[item].length > 1 ? screenTypeValues[item].filter(subItem => subItem !== item) : screenTypeValues[item]
            list = [...list, ...types]
        } else {
            list = [...list, item]
        }

    })
    return list
}

// 过滤出已选数据
export const filteredWordList = (dataSource = {}, regionList = []) => {
    const screenTypeValues = getTypeValues(regionList);
    const data = dataSource.map(item => {
        return item.value || item.code || item
    })
    const datas = changeDditData(screenTypeValues, data)
    const options = getScreenType(datas, screenTypeValues);

    const lists = regionList.reduce((mome, item) => {
        const key = item.value
        mome[key] = {
            children: item.children,
            type: { value: item.value, label: item.label }
        }
        return mome
    }, {})
    let btnData = {};
    for (let key in options) {
        const values = options[key];
        const list = lists[key];
        if (list) {
            const children = list.children || [];
            const type = list.type;
            children.forEach((subItem) => {
                if (values.indexOf(subItem.value) > -1 || key === subItem.value) {
                    const indicator = type.value
                    if (btnData[indicator]) {
                        btnData[indicator] = { ...btnData[indicator], children: [...btnData[indicator].children, subItem] }
                    } else {
                        btnData[indicator] = {
                            ...type,
                            children: [{ ...subItem }]
                        }
                    }
                }
            })
        }
    }
    const btnDatas = Object.values(btnData)
    return btnDatas
}

export const changeMessageData = (data, key="3", reKey="50") => {
    const newData = [...data]
   const index = _.indexOf(newData, key)
   if(index !== -1) {
    newData.splice(index, 1, reKey)
   }
   return newData

}
