/*
 * @Description: 更多筛选
 * @Autor: 吴少波
 * @Date: 2022-06-30
 */

import { guid } from '@/utils/utils'

const singleStateConing = {
    selectRegisterCapital: 'UNLIMITED',
    selectRegisterTime: 'UNLIMITED',
    selectEnterpriseScale: 'UNLIMITED'
}

export const MORE_SET_AMOUNT_TYPE = 'more/MORE_SET_AMOUNT_TYPE';
export const FETCH_INITAL_STATE = 'more/FETCH_INITAL_STATE';
export const SET_LOWER_LIMIT = 'more/SET_LOWER_LIMIT';
export const SET_AMOUNT_UPPER_LIMIT = 'more/SET_AMOUNT_UPPER_LIMIT';
export const SET_COMTACT_TYPE = 'more/SET_COMTACT_TYPE';
export const CHANGE_SEARCH_RANG = 'more/CHANGE_SEARCH_RANG';
export const CHANGE_SEARCH_MODE = 'more/CHANGE_SEARCH_MODE';
export const CHANGE_EDIT_EXCLUDE_WORD= 'more/CHANGE_EDIT_EXCLUDE_WORD';
export const CHANG_ENTERPRISE_TYPE = 'more/CHANG_ENTERPRISE_TYPE';
export const CHANG_REGISTER_CAPITAL = 'more/CHANG_REGISTER_CAPITAL';
export const CHANGE_REGISTER_LOWER_LIMIT = 'more/CHANGE_REGISTER_LOWER_LIMIT';
export const CHANGE_REGISTER_UPPER_LIMIT = 'more/CHANGE_REGISTER_UPPER_LIMIT';
export const CHANGE_REGISTER_TIME = 'more/CHANGE_REGISTER_TIME';
export const CHANGE_REGISTER_TIME_LOWER_LIMIT = 'more/CHANGE_REGISTER_TIME_LOWER_LIMIT';
export const CHANGE_REGISTER_TIME_UPPER_LIMIT = 'more/CHANGE_REGISTER_TIME_UPPER_LIMIT';
export const CHANGE_ENTERPRI_SECALE = 'more/CHANGE_ENTERPRI_SECALE';
export const CHANGE_ENTERPRISE_SCALE_LOWER_LIMIT = 'more/CHANGE_ENTERPRISE_SCALE_LOWER_LIMIT';
export const CHANGE_ENTERPRISE_SCALE_UPPER_LIMIT = 'more/CHANGE_ENTERPRISE_SCALE_UPPER_LIMIT';
export const CHANGE_CONSTRUCTION_QUALIFY = 'more/CHANGE_CONSTRUCTION_QUALIFY';
export const CHANGE_SELECT_NEW_CONTACT_TYPE = 'more/CHANGE_SELECT_NEW_CONTACT_TYPE';
export const SET_PURCHASE_TIME_LOWER_LIMIT = 'more/SET_PURCHASE_TIME_LOWER_LIMIT';
export const SET_PURCHASE_TIME_UPPER_LIMIT = 'more/SET_PURCHASE_TIME_UPPER_LIMIT';
export const SET_START_DATE = 'more/SET_START_DATE';
export const SET_END_DATE = 'more/SET_END_DATE';
export const SET_PURCHASE_TIME = 'more/SET_PURCHASE_TIME';
export const CHANGE_IS_HAHGT = 'more/CHANGE_IS_HAHGT';
export const CHANG_PROJECT_CLOSE = 'more/CHANG_PROJECT_CLOSE';

const initialState = {
    selectAmountType: 'UNLIMITED', // 项目金额
    amountLowerLimit: '',
    amountUpperLimit: '',
    selectContactType: '0', //联系方式
    selectSearchRange: '0', // 搜索范围
    selectSearchMode: '1', // 搜索模式
    excludeToData: [{ key: guid(), value: "" }], // 排除词
    selectEnterpriseType: [], // 公司类型
    selectRegisterCapital: 'UNLIMITED', // 注册资本
    registerCapitalLowerLimit: '',
    registerCapitalUpperLimit: '',
    selectRegisterTime: [], // 注册年限
    registerTimeLowerLimit: '',
    registerTimeUpperLimit: '',
    selectEnterpriseScale: [], // 企业规模
    enterpriseScaleLowerLimit: '',
    enterpriseScaleUpperLimit: '',
    selectConstructionQualify: 'UNLIMITED', // 建筑资质
    selectNewContactType: '0', // new 联系方式
    purchaseTimeLowerLimit: '', // 采购时间下限
    purchaseTimeUpperLimit: '', // 采购时间上限
    startDate: new Date(Date.now()), // 开始时间
    endDate: new Date(Date.now()), // 结束时间
    selectPurchaseTime: 'LASTHALFYEAR',// 选择时间
    isLight: false// 是否高亮
}

const visibleState = {
    visible: false
}

export default (state = {...initialState, ...visibleState}, action: any) => {
    switch (action.type) {
        case CHANG_PROJECT_CLOSE:
            return {
                ...state,
                visible: action.visible
            }
        case CHANGE_IS_HAHGT:
            return {
                ...state,
                isLight: action.isLight
            }
        case SET_PURCHASE_TIME:
            return {
                ...state,
                selectPurchaseTime: action.purchaseTime
            }
        case SET_START_DATE:
            return {
                ...state,
                startDate: action.startDate
            }
        case SET_END_DATE:
            return {
                ...state,
                endDate: action.endDate
            }
        case SET_PURCHASE_TIME_LOWER_LIMIT:
            return {
                ...state,
                purchaseTimeLowerLimit: action.purchaseTimeLowerLimit
            }
        case SET_PURCHASE_TIME_UPPER_LIMIT:
            return {
                ...state,
                purchaseTimeUpperLimit: action.purchaseTimeUpperLimit 
            }
        case CHANGE_SELECT_NEW_CONTACT_TYPE:
            return {
                ...state,
                selectNewContactType: action. selectNewContactType
            }
        case CHANGE_CONSTRUCTION_QUALIFY:
            return {
                ...state,
                selectConstructionQualify: action.selectConstructionQualify
            }
        case CHANGE_ENTERPRISE_SCALE_LOWER_LIMIT:
            return {
                ...state,
                enterpriseScaleLowerLimit: action.enterpriseScaleLowerLimit
            }
        case CHANGE_ENTERPRISE_SCALE_UPPER_LIMIT:
            return {
                ...state,
                enterpriseScaleUpperLimit: action.enterpriseScaleUpperLimit
            }
        case CHANGE_ENTERPRI_SECALE:
            return {
                ...state,
                selectEnterpriseScale: action.selectEnterpriseScale
            }
        case CHANGE_REGISTER_TIME_LOWER_LIMIT:
            return {
                ...state,
                registerTimeLowerLimit: action.registerTimeLowerLimit
            }
        case CHANGE_REGISTER_TIME_UPPER_LIMIT: {
            return {
                ...state,
                registerTimeUpperLimit: action.registerTimeUpperLimit
            }
        }
        case CHANGE_REGISTER_TIME:
            return {
                ...state,
                selectRegisterTime: action.selectRegisterTime
            }
        case CHANGE_REGISTER_LOWER_LIMIT:
            return {
                ...state,
                registerCapitalLowerLimit: action.registerCapitalLowerLimit
            }
        case CHANGE_REGISTER_UPPER_LIMIT:
            return {
                ...state,
                registerCapitalUpperLimit: action.registerCapitalUpperLimit
            }
        case CHANG_REGISTER_CAPITAL:
            return {
                ...state,
                selectRegisterCapital: action.selectCapital
            }
        case MORE_SET_AMOUNT_TYPE:
            return {
                ...state,
                selectAmountType: action.selectAmountType
            }
        case SET_LOWER_LIMIT:
            return {
                ...state,
                amountLowerLimit: action.amountLowerLimit
            }
        case SET_AMOUNT_UPPER_LIMIT:
            return {
                ...state,
                amountUpperLimit: action.amountUpperLimit
            }
        case SET_COMTACT_TYPE:
            return {
                ...state,
                selectContactType: action.selectContactType
            }
        case CHANGE_SEARCH_RANG:
            return {
                ...state,
                selectSearchRange: action.selectSearchRange
            }
        case CHANGE_SEARCH_MODE:
            return {
                ...state, 
                selectSearchMode: action.selectSearchMode
            }
        case CHANGE_EDIT_EXCLUDE_WORD:
            return {
                ...state, 
                excludeToData: action.excludeToData
            }
        case CHANG_ENTERPRISE_TYPE:
            return {
                ...state, 
                selectEnterpriseType: action.selectEnterpriseType
            }
        case FETCH_INITAL_STATE:
            const params = +action.tab === 1 ? singleStateConing : {}
            return {
                ...initialState,
                ...params,

            }
        default:
            return state;
    }
}

export const changeProjectClose = (ownProps: any) => {
    return {
        type: CHANG_PROJECT_CLOSE,
        visible: ownProps.value
    }
}

export const setPurchaseTime = (ownProps: any) => {
    return {
        type: SET_PURCHASE_TIME,
        purchaseTime: ownProps.value
    }
}

export const setStartDate = (ownProps: any) => {
    return {
        type: SET_START_DATE,
        startDate: ownProps.value
    }
}

export const setEndDate = (ownProps: any) => {
    return {
        type: SET_END_DATE,
        endDate: ownProps.value
    }
}

export const setPurchaseTimeLowerLimit = (ownProps: any) => {
    return {
        type: SET_PURCHASE_TIME_LOWER_LIMIT,
        purchaseTimeLowerLimit: ownProps.value
    }
}

export const setPurchaseTimeUpperLimit = (ownProps: any) => {
    return {
        type: SET_PURCHASE_TIME_UPPER_LIMIT,
        purchaseTimeUpperLimit: ownProps.value
    }
}

export const changeSelectNewContactType = (ownProps: any) => {
    return {
        type: CHANGE_SELECT_NEW_CONTACT_TYPE,
        selectNewContactType: ownProps.value
    }
}

export const changeSelectConstructionQualify = (ownProps: any) => {
    return {
        type: CHANGE_CONSTRUCTION_QUALIFY,
        selectConstructionQualify: ownProps.value
    }
}

export const changeSelectEnterpriseScale = (ownProps: any) => {
    return {
        type: CHANGE_ENTERPRI_SECALE,
        selectEnterpriseScale: ownProps.value
    }
}
export const changeEnterpriseScaleLowerLimit = (ownProps: any) => {
   return {
        type: CHANGE_ENTERPRISE_SCALE_LOWER_LIMIT,
        enterpriseScaleLowerLimit: ownProps.value
   }
}
export const changeEnterpriseScaleUpperLimit = (ownProps: any) => {
    return {
        type: CHANGE_ENTERPRISE_SCALE_UPPER_LIMIT,
        enterpriseScaleUpperLimit: ownProps.value
    }
}
export const changeRegisterTimeUpperLimit = (ownProps: any) => {
    return {
        type: CHANGE_REGISTER_TIME_UPPER_LIMIT,
        registerTimeUpperLimit: ownProps.value
    }
}
export const changeRegisterTimeLowerLimit = (ownProps: any) => {
    return {
        type: CHANGE_REGISTER_TIME_LOWER_LIMIT,
        registerTimeLowerLimit: ownProps.value
    }
}
export const changeRegisterTime = (ownProps: any) => {
    return {
        type: CHANGE_REGISTER_TIME,
        selectRegisterTime: ownProps.value
    }
}


export const changeRegisterCapitalLowerLimit = (ownProps: any) => {
    return {
        type: CHANGE_REGISTER_LOWER_LIMIT,
        registerCapitalLowerLimit: ownProps.value
    }
}
export const changeRegisterCapitalUpperLimit = (ownProps: any) => {
    return {
        type: CHANGE_REGISTER_UPPER_LIMIT,
        registerCapitalUpperLimit: ownProps.value
    }
}

export const changeSelectCapital = (ownProps: any) => {
    return {
        type: CHANG_REGISTER_CAPITAL,
        selectCapital: ownProps.value
    }
}

export const changeSelectAmountType = (ownProps: any) => { // 金额
    return {
        type: MORE_SET_AMOUNT_TYPE,
        selectAmountType: ownProps.value
    }

}
export const setAmountLowerLimit = (ownProps: any) => { // 金额最低自定义
    return {
        type: SET_LOWER_LIMIT,
        amountLowerLimit: ownProps.value
    }
}
export const setAmountUpperLimit = (ownProps: any) => { // 金额最高自定义
    return {
        type: SET_AMOUNT_UPPER_LIMIT,
        amountUpperLimit: ownProps.value
    }
}
export const changeSelectContactType = (ownProps: any) => { // 联系方式
     return {
        type: SET_COMTACT_TYPE,
        selectContactType: ownProps.value
     }
}
export const changeSelectSearchRange = (ownProps: any) => { // 搜索范围
    return {
        type: CHANGE_SEARCH_RANG,
        selectSearchRange: ownProps.value
    }
}
export const changeSelectSearchMode = (ownProps: any) => { // 搜索模式
    return {
        type: CHANGE_SEARCH_MODE,
        selectSearchMode: ownProps.value
    }
}
export const changEdiExcludeWord = (ownProps: any) => { // 排除词
    return {
        type: CHANGE_EDIT_EXCLUDE_WORD,
        excludeToData: ownProps.value
    }
}
export const changEnterpriseType = (ownProps: any) => {
    return {
        type: CHANG_ENTERPRISE_TYPE,
        selectEnterpriseType: ownProps.value
    }
}



// 重置
export const fetchInitialState = (ownProps: any) => {
    return {
        type: FETCH_INITAL_STATE,
        tab: ownProps.tab
    }
}

// 是否高亮
export const changeIsHight = (ownProps: any) => {
    return {
        type: CHANGE_IS_HAHGT,
        isLight: ownProps.isLight
    } 

}