/*
 * @Description:
 * @Autor: wushaobo
 * @Date: 2022-02-10
 */

export const REGION_DATA = 'seniorRegion/REGION_DATA';

const initialState = {
    regionData: {} // 地域数据
}

export default (state = initialState, action: any) => {
    if (action.type === REGION_DATA) {
        return {
            ...state,
            regionData: action.regionData
        }
    }
    return state;
}

export const getSeniorRegionData = (ownProps: any) => (dispatch: Function) => {
    new Promise(resolve => {
        dispatch({
            type: REGION_DATA,
            regionData: ownProps.regionData
        })
    })
}