/*
 * @Description:
 * @Autor: sunshaoqing
 * @Date: 2021-10-13 17:53:31
 */
export const getSystem = () => {
  if(typeof(navigator)=='undefined'){
    return
  }
  let appEnv = 'mobileh5';
  var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //在微信中打开
    appEnv = 'wx';
  }
  return appEnv;
};
