
export const COLLAPSEADD = 'subscribeCenter/SUBSCRIBE/ADD';
export const NEWEDITSUBSCRIBE = 'subscribeCenter/NEW/EDIT/SUBSCRIBE';
export const CONTENTNAMW = 'subscribeCenter/CONTENTA/NAME';
export const SUBSCRIBE_TYPE = 'subscribe/SUBSCRIBE/TYPE';
export const SUBSCRIBE_NEWS = 'subscribe/SUBSCRIBE/NEWS';
export const SUBSCRIBE_INDUSTRY = 'subscribe/SUBSCRIBE/INDUSTRY';
export const SUBSCRIBE_SEARCH_SCOP = 'subscribe/SUBSCRIBE/SEARCHSCOP';
export const SUBSCRIBE_SEARCH_MODEL = 'subscribe/SUBSCRIBE/SEARCHMODEL';
export const SUBSCRIBE_INITIALIZED = 'subscribe/INITIALIZED';
export const SUBSCRIBE_DATA_ITEM = 'subscribe/ SUBSCRIBE/DATA/ITEM';
export const SET_SUBSCRIBE_NAME = 'subscribe/SET_SUBSCRIBE_NAME';
export const SET_NEWS_TYPES_STATUS = "subscribe/SET_NEWS_TYPES_STATUS";
export const SET_NEWS_REGION_STATUS = 'subscribe/SET_NEWS_REGION_STATUS';
export const SET_NEWS_INDUSTRY_STATUS = 'subscribe/SET_NEWS_INDUSTRY_STATUS';
export const GET_TOTAL_NEWS = 'subscribe/GET_TOTAL_NEWS';
export const GET_TOTAL_INDUSTRY = 'subscribe/GET_TOTAL_INDUSTRY';
export const GET_TOTAL_NIC_OWNERS = 'subscribe/GET_TOTAL_NIC_OWNERS';
export const GET_TOTAL_REGION = 'subscribe/GET_TOTAL_REGION';
export const GET_KEYWORKS_DATA = 'subscribe/GET_KEYWORKS_DATA';
export const GET_EXCLUDE_WORDS = 'subscribe/GET_EXCLUDE_WORDS';
export const GET_USER_INFO = 'subscribe/GET_USER_INFO';
export const GET_TOTAL_SUBSCRIBE = 'subscribe/GET_TOTAL_SUBSCRIBE';
export const GET_INFORMATION_TYPE = 'subscribe/GET_INFORMATION_TYPE';
export const GET_KEYWORD_TYPE = 'subscribe/GET_KEYWORD_TYPE';
export const GET_SUBSCRIBE_SEARCH = 'subscribe/GET_SUBSCRIBE_SEARCH';
export const GET_SELECT_ID = 'subscribe/GET_SELECT_ID';
export const IS_GROUP_SUBSCRIBE = 'subscribe/IS_GROUP_SUBSCRIBE';
export const SUBSCRIBEE_SERARCH = 'subscribe/SUBSCRIBEE_SERARCH';
export const SUBSCRIBEE_SERARCH_PAGE = 'subscribe/SUBSCRIBEE_SERARCH_PAGE';
export const INFORMATION_TYPE_LIST = 'subscribe/INFORMATION_TYPE_LIST';
export const SET_CREATE_TIME = 'subscribe/SET_CREATE_TIME';
export const SET_PAGE_NAUMBER = 'subscribe/SET_PAGE_NAUMBER';
export const GET_DATA_BACKFILL = 'subscribe/GET_DATA_BACKFILL';
export const GET_DATA_BACKFILL_CLOSE = "subscribe/GET_DATA_BACKFILL_CLOSE";
export const HAS_MORE = "subscribe/HAS_MORE";
export const IS_FIRST_SUBSCRIBE = "subscribe/IS_FIRST_SUBSCRIBE";
export const SET_PUSH_TIMES = "subscribe/SET_PUSH_TIMES";
export const SET_PAGE_LOADING = "subscribe/SET_PAGE_LOADING";
export const CHANGE_SUBSCRIBE_PROJECT_DETAILS = 'subscribe/CHANGE_SUBSCRIBE_PROJECT_DETAILS';


const initialState = {
    keywords: [], // 选择关键词
    pushTimes: [],
    countArry: [1],
    newEditName: '新建',
    typeSubscribe: '12',
    newsSubscribe: '需求公告、招标公告、谈判公告、协商公告',
    industrySubscribe: '',
    searchScopSubscribe: '0',
    searchModelSubscribe: 1,
    // dataItem: [],
    name: '',
    newsStatus: [],
    newsRegion: [],
    newsIndustry: [],
    totalNews: [], // 总的信息
    toalRegion: [], // 地域总数
    toalIndustry: [], // 行业总数
    totalNicOwners: [],  // 业主
    keywordsData: [], // 关键词编辑返回数据
    excludeWords: [],  // 排除词编辑数据
    userInfoData: {},
    id: '',
    infoTypesData: [],
    keywordsList: [],
    searchData: [],
    subscribeId: '',
    isGroupSubscribe: true, // 监控还是订阅
    subscribeSearchData: [], // 列表数据
    subscribePage: 1,// 订阅器页码
    informationType: [], // 信息类型数据
    hasMore: true, // 是否下滑请求数据
    createTime: '',
    subscribeCount: 0,
    page: 2,
    // 回填数据参数
    backfillData: {
        updatedTime: 'TODAY',
        updatedTimeLowerLimit: '', // 自定义时间
        updatedTimeUpperLimit: '',  // 自定义时间
        tab: '12',   // tab
        keywordsSelected: '', // 关键词
        infoTypes: {}, // 信息
        id: '',   // 订阅器名称ID
        mid: '',   // 数据id
        perPage: 10,  // 页码
        scrollTop: 0,
        isProject: false, // 公司
        isCompany: false, // 单位
        keywordValue: [0],
        regions: {},
        nicOwners: {}
    },
    isBackfill: false, // 是否需要回填数据
    isFirst: true, // 是否首次进入
    loading: false
};


//新建编辑页面 新增关键词分组
export default (state = initialState, action={}) => {
    switch (action.type) {
        case SET_PAGE_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case SET_PUSH_TIMES:
            return {
                ...state,
                pushTimes: action.pushTimes
            }
        case IS_FIRST_SUBSCRIBE:
            return {
                ...state,
                isFirst: false 
            }
        case HAS_MORE:
            return {
                ...state,
                hasMore: false
            }
        case GET_DATA_BACKFILL_CLOSE:
            return {
                ...state,
                isBackfill: false
            }
        case GET_DATA_BACKFILL:
            return {
                ...state,
                backfillData: action.backfillData,
                isBackfill: action.isBackfill
            }
        case SET_PAGE_NAUMBER:
            return {
                ...state,
                page: action.page
            }
        case SET_CREATE_TIME:
            return {
                ...state,
                createTime: action.createTime
            }
        case INFORMATION_TYPE_LIST:
            return {
                ...state,
                informationType: action.informationType
            }
        case SUBSCRIBEE_SERARCH_PAGE:
            return {
                ...state,
                subscribeSearchData: [...state.subscribeSearchData, ...action.subscribeSearchData],
                hasMore: true
            }
        case SUBSCRIBEE_SERARCH:
            return {
                ...state,
                subscribeSearchData: action.subscribeSearchData,
                subscribePage: action.subscribePage,
                subscribeCount: action.subscribeCount,
                hasMore: true
            }
        case CHANGE_SUBSCRIBE_PROJECT_DETAILS:
            return {
                ...state,
                subscribeSearchData: action.data 
            }
        case GET_SELECT_ID:
            return {
                ...state,
                subscribeId: action.subscribeId
            }
        case GET_SUBSCRIBE_SEARCH:
            return {
                ...state,
                searchData: action.searchData
            }
        case GET_KEYWORD_TYPE:
            return {
                ...state,
                keywordsList: action.keywordsList,
                keywords: action.keywords
            }
        case GET_INFORMATION_TYPE:
            return {
                ...state,
                infoTypesData: action.infoTypesData
            }
        case COLLAPSEADD:
            return {
                ...state,
                countArry: action.countArry
            };
        case NEWEDITSUBSCRIBE:
            return {
                ...state,
                newEditName: action.newEditName
            }
        case SUBSCRIBE_TYPE:
            return {
                ...state,
                typeSubscribe: action.typeSubscribe
            }
        case SUBSCRIBE_NEWS:
            return {
                ...state,
                newsSubscribe: action.newsSubscribe
            }
        case SUBSCRIBE_INDUSTRY:
            return {
                ...state,
                industrySubscribe: action.industrySubscribe
            }
        case SUBSCRIBE_SEARCH_SCOP:
            return {
                ...state,
                searchScopSubscribe: action.searchScopSubscribe
            }
        case SUBSCRIBE_SEARCH_MODEL:
            return {
                ...state,
                searchModelSubscribe: action.searchModelSubscribe
            }
        case SUBSCRIBE_INITIALIZED:
            return {
                ...state,
                countArry: [1],
                typeSubscribe: '12',
                newsSubscribe: '',
                industrySubscribe: '',
                searchScopSubscribe: '0',
                searchModelSubscribe: 1,
                toalIndustry: [],
                nicOwners: [],
                toalRegion: [],
                keywordsData: [[], []],
                excludeWords: [],
                newsStatus: [],
                newsRegion: [],
                newsIndustry: [],
                totalNews: [],
                name: ''

            }
        case SET_SUBSCRIBE_NAME:
            return {
                ...state,
                name: action.name
            }
        case SET_NEWS_TYPES_STATUS:
            return {
                ...state,
                newsStatus: action.newsStatus
            }
        case SET_NEWS_REGION_STATUS:
            return {
                ...state,
                newsRegion: action.newsRegion
            }
        case SET_NEWS_INDUSTRY_STATUS:
            return {
                ...state,
                newsIndustry: action.newsIndustry
            }
        case GET_TOTAL_NEWS:
            return {
                ...state,
                totalNews: action.totalNews
            }
        case GET_TOTAL_INDUSTRY:
            return {
                ...state,
                toalIndustry: action.toalIndustry
            }

        case GET_TOTAL_NIC_OWNERS:
            return {
                ...state,
                totalNicOwners: action.totalNicOwners
            }
        case GET_TOTAL_REGION:
            return {
                ...state,
                toalRegion: action.toalRegion
            }
        default:
            break;
    }
    switch (action.type) {
        case GET_KEYWORKS_DATA:
            return {
                ...state,
                keywordsData: action.keywordsData
            }
        case GET_EXCLUDE_WORDS:
            return {
                ...state,
                excludeWords: action.excludeWords
            }
        case GET_USER_INFO:
            return {
                ...state,
                userInfoData: action.userInfoData
            }
        case IS_GROUP_SUBSCRIBE:
            return {
                ...state,
                isGroupSubscribe: action.isGroupSubscribe
            }
        case GET_TOTAL_SUBSCRIBE:
            return {
                ...state,
                name: action.name,
                typeSubscribe: action.typeSubscribe,
                searchScopSubscribe: action.searchScopSubscribe,
                searchModelSubscribe: action.searchModelSubscribe,
                id: action.id
            }
        default:
            return state;
    }
}

export const changeSubscribeData = ownProps => {
    return {
        type: CHANGE_SUBSCRIBE_PROJECT_DETAILS,
        data: ownProps.data
    }
}

// loading
export const setPageLoading = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_PAGE_LOADING,
            loading: ownProps.loading
        })
    })
}
// 给推送时间赋值
export const setPushTimes = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_PUSH_TIMES,
            pushTimes: ownProps.pushTimes
        })
    })
}

// 第一次进入订阅
export const changeFirstSubscribe = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: IS_FIRST_SUBSCRIBE
        })
    })
}
// 下滑数据没回来, 不请求数据
export const changHasMore = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: HAS_MORE
        })
    })
}

// 数据回填关闭
export const changeCloseBackfill = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_DATA_BACKFILL,
            isBackfill: false,
            backfillData: {
                updatedTime: 'TODAY',
                updatedTimeLowerLimit: '', // 自定义时间
                updatedTimeUpperLimit: '',  // 自定义时间
                tab: '12',   // tab
                keywordsSelected: '', // 关键词
                infoTypes: [], // 信息
                id: '',   // 订阅器名称ID
                mid: '',   // 数据id
                perPage: 10,  // 页码
                isProject: false, // 公司
                isCompany: false, // 单位
                keywordValue: [0],
                nicDatas:[],   // 行业
                nicOwners: [], // 业主
                regions: []
            }
        })
    })
}

// 数据回填
export const getDataBackfill = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_DATA_BACKFILL,
            backfillData: ownProps.backfillData,
            isBackfill: ownProps.isBackfill
        })
    })
}

// 下拉页数更新
export const getPagStatus = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_PAGE_NAUMBER,
            page: ownProps.page
        })
    })
}

// 获取时间
export const getCreateTime = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_CREATE_TIME,
            createTime: ownProps.createTime
        })
    })
}

// 获取信息类型数据
export const getInformationType = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: INFORMATION_TYPE_LIST,
            informationType: ownProps.informationType
        })
    })

}

export const getSubscribeSearchPageData = ownProps => dispatch => [
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBEE_SERARCH_PAGE,
            subscribeSearchData: ownProps.subscribeSearchData,
            subscribePage: ownProps.subscribePage,
            subscribeCount: ownProps.subscribeCount,
            hasMore: true
        })
    })
]

// 获取数据
export const getSubscribeSearchData = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBEE_SERARCH,
            subscribeSearchData: ownProps.subscribeSearchData,
            subscribePage: ownProps.subscribePage,
            subscribeCount: ownProps.subscribeCount,
            hasMore: true
       })
    })
}

// 监控还是订阅
export const groupSubscribe = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: IS_GROUP_SUBSCRIBE,
            isGroupSubscribe: ownProps.isGroupSubscribe
        })
    })
}

export const setSubscribeId = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_SELECT_ID,
            subscribeId: ownProps.subscribeId
        })
    })
}

// 数据列表
export const setSubscribeSearch = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_SUBSCRIBE_SEARCH,
            searchData: ownProps.searchData
        })
    })
}

//信息类型数据type
export const setNewsTypeStatus = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_NEWS_TYPES_STATUS,
            newsStatus: ownProps.newsStatus
        })
    })
}

// 所在地区数据tyep
export const setNewsRegion = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_NEWS_REGION_STATUS,
            newsRegion: ownProps.newsRegion
        })
    })
}
// 所在行业type
export const setNewsIndustry = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_NEWS_INDUSTRY_STATUS,
            newsIndustry: ownProps.newsIndustry
        })
    })
}


export const setCountArry = (own) => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: COLLAPSEADD,
            countArry: own.countArry
        })
    })

export const setNewEbitName = (ownProps) => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: NEWEDITSUBSCRIBE,
            newEditName: ownProps.newEditName
        })
    })

// 订阅器类型
export const setSubscribeType = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBE_TYPE,
            typeSubscribe: ownProps.typeSubscribe
        })
    })
}

// 信息类型
export const setSubscribeNews = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBE_NEWS,
            newsSubscribe: ownProps.newsSubscribe
        })
    })
}
// 信息类型确认
export const getTotalNews = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_TOTAL_NEWS,
            totalNews: ownProps.totalNews
        })
    })
}

// 业主分类  确认
export const getTotalNicOwners = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_TOTAL_NIC_OWNERS,
            totalNicOwners: ownProps.totalNicOwners
        })
    })
}


// 行业分类
export const setIndustry = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBE_INDUSTRY,
            industrySubscribe: ownProps.industrySubscribe
        })
    })
}


// 行业分类总数  确认
export const getTotalIndustry = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_TOTAL_INDUSTRY,
            toalIndustry: ownProps.toalIndustry
        })
    })
}
// 所在地区总数  确认
export const getTotalRegion = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_TOTAL_REGION,
            toalRegion: ownProps.toalRegion
        })
    })
}
// 搜索范围
export const setSearchScop = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBE_SEARCH_SCOP,
            searchScopSubscribe: ownProps.searchScopSubscribe
        })
    })
}
//搜索模式
export const setSearchModel = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBE_SEARCH_MODEL,
            searchModelSubscribe: ownProps.searchModelSubscribe
        })
    })
}

// 恢复初始化
export const initializeSubscribe = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SUBSCRIBE_INITIALIZED

        })
    })
}

// 订阅器管理编辑
// export const getSubscribeData = ownProps => dispatch => {
//     new Promise(resolve => {
//         dispatch({
//             type: SUBSCRIBE_DATA_ITEM,
//             dataItem: ownProps.dataItem
//         })
//     })
// }

// 订阅器名称
export const setSubscribeName = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: SET_SUBSCRIBE_NAME,
            name: ownProps.name
        })
    })
}

// 接口返回关键词
export const getKeywordsData = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_KEYWORKS_DATA,
            keywordsData: ownProps.keywordsData
        })
    })
}
// 接口返回排除词
export const getExcludeWordsData = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_EXCLUDE_WORDS,
            excludeWords: ownProps.excludeWords
        })
    })
}

// 订阅推送
export const getUserInfoData = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_USER_INFO,
            userInfoData: ownProps.userInfoData
        })
    })
}

// 编辑传递存储当前值
export const getTotalSubscribe = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_TOTAL_SUBSCRIBE,
            name: ownProps.name,
            searchScopSubscribe: ownProps.searchScopSubscribe,
            searchModelSubscribe: ownProps.searchModelSubscribe,
            id: ownProps.id
        })
    })
}

// 信息类型数据
export const getInfoTypes = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_INFORMATION_TYPE,
            infoTypesData: ownProps.infoTypesData
        })
    })
}

// 关键词数据
export const getKeywordsList = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_KEYWORD_TYPE,
            keywordsList: ownProps.keywordsList,
            keywords: ownProps.keywords

        })
    })
}



