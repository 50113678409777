import Cookies from 'js-cookie'

export const COLLAPSE = 'layout/COLLAPSE'

const initialState = {
    isCollapsed: false,
};

export default (state = initialState, action) => {
    if (action.type === COLLAPSE) {
        return {
          ...state,
          isCollapsed: action.collapsed
        };
    }
    return state;
}

export const toggleSiderMenu = (collapsed) => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: COLLAPSE,
            collapsed
        })
    })

