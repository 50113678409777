/**
* @file actions utils
* @author wushaobo
*/

import {mapValues, isArray} from 'lodash';
import keyMirror from 'keymirror';

export const mapActionTypesToPrefix = actionTypes => prefix => mapValues(keyMirror(actionTypes), actionType => {
    if (isArray(prefix)) {
        return `${prefix.join('_').toUpperCase()}_${actionType}`;
    }
    if (prefix) {
        return `${prefix.toUpperCase()}_${actionType}`;
    }
    return `${actionType}`;
});

export const combineActionTypes = (...allActionTypes) => prefix => {
    return allActionTypes
        .map(actionTypes => actionTypes(prefix))
        .reduce((memo, item) => ({
            ...memo,
            ...item
        }), {});
};
