export const MONITOR_ENTERPRISE_ID = 'monitor/MONITOR_ENTERPRISE_ID';
export const MONITOR_CORPS = "monitor/MONITOR_CORPS";
export const MOITOR_PAGE = "monitor/MOITOR_PAGE";
export const MONITOR_DROP = 'monitor/MONITOR_DROP';
export const GET_MORE_NAME = 'GET_MORE_NAME';
export const CHANGE_BACK_FILL_DATA = 'monitor/CHANGE_BACK_FILL_DATA';
export const CHANGE_MOITOR_STATE = 'monitor/CHANGE_MOITOR_STATE'

const initialState = {
    monitorId: '',
    monitorPage: {},
    monitorData: [],
    moreNameRow: [],
    isMonitorBackfill: false, // 是否需要回填数据
    hasMore: false,
    count: 0,
    page:1,
    backfillData: {
        updatedTime: 'TODAY',
        updatedTimeLowerLimit: '', // 自定义时间
        updatedTimeUpperLimit: '',  // 自定义时间
        keywordsSelected: '', // 关键词
        infotypes: [], // 信息类型
        id: '',  // 分组名称id
        backfillId: '', // 回填id
        entId: '',
        mid: '',
        keywordValue: '[]',
        isPoject: false,
        isCompany: false,
        regions: [],
        trade:[],
        infotypesMap: {},
        tradeMap: {}
    }
}

export default (state = initialState, action={}) => {
    switch (action.type) {
        case CHANGE_BACK_FILL_DATA:
            return {
                ...state,
                isMonitorBackfill: action.isMonitorBackfill,
                backfillData: action.backfillData
            }
        case MONITOR_ENTERPRISE_ID:
            return {
                ...state,
                monitorId: action.monitorId
            }
        case MONITOR_CORPS:
            return {
                ...state,
                monitorData: action.page ===1 ? action.monitorData: [...state.monitorData,...action.monitorData],
                hasMore: action.hasMore,
                count: action.count,
                page: action.page
            }
        case MOITOR_PAGE:
            return {
                ...state,
                monitorPage: {...state.monitorPage,  ...action.monitorPage}
            }
        case GET_MORE_NAME:
            return {
                ...state,
                moreNameRow: action.moreNameRow
            }
        case CHANGE_MOITOR_STATE:
            return {
                ...state,
                monitorPage: {...state.monitorPage,  ...action.monitorPage}
            }
  
        default:
            return state;
    }

}

export const changeMoitorState = (ownProps) => {
    return {
        type: CHANGE_MOITOR_STATE,
        monitorPage: ownProps.data
    }

}

// 数据回填
export const changeBackfillData = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: CHANGE_BACK_FILL_DATA,
            backfillData: ownProps.backfillData,
            isMonitorBackfill: ownProps.isMonitorBackfill
        })
    })
}
// 关闭回填数据
export const closeBackfillData = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: CHANGE_BACK_FILL_DATA,
            backfillData: {
                updatedTime: 'TODAY',
                updatedTimeLowerLimit: '', // 自定义时间
                updatedTimeUpperLimit: '',  // 自定义时间
                keywordsSelected: '', // 关键词
                infotypes: [], // 信息类型
                id: '',  // 分组名称id
                backfillId: '', // 回填id
                entId: '',
                mid: '',
                keywordValue: '[]',
                isPoject: false,
                isCompany: false,
                regions: [],
                infotypesMap: {},
                tradeMap: {}
                
            },
            isMonitorBackfill: false
        })
    })
}


// 监控企业id
export const getMonitorId = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: MONITOR_ENTERPRISE_ID,
            monitorId: ownProps.monitorId
        })
    })
}

// 监控中心查询企业
export const getMonitorCorpsStatus = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: MONITOR_CORPS,
            monitorData: ownProps.monitorData,
            count: ownProps.count,
            page: ownProps.page,
            hasMore: ownProps.hasMore
        })
    })
}

// 监控中心数据详情
export const getMonitorPage = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: MOITOR_PAGE,
            monitorPage: ownProps.monitorPage
        })
    })
}

// 监控更多name数据
export const getMoreName = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_MORE_NAME,
            moreNameRow: ownProps.moreNameRow
        })
    })
}
