/*
 * @Description:
 * @Autor: wushaobo
 * @Date: 2021-10-19 
 */


export const PROVINCESS = 'GET_PROVINCESS';
export const CODE_NIC = 'GET_CODE_NIC';
export const CODE_NIC_OWNER = 'GET_CODE_NIC_OWNER';
export const GET_CODE_REGION = 'provinces/GET_CODE_REGION';
export const CHECK_IMG = 'provinces/CHECK_IMG';
export const GET_REGION_DATA_ENTRIES = 'provinces/GET_REGION_DATA_ENTRIES';

const initialState = {
    provincesData: [],
    nicData: [],     //  行业分类
    nicOwner: [],     // 业主分类
    regionData: [],
    imgList: {},
    regionDataEntries: {}
};

export default (state = initialState, action={}) => {
    switch (action.type) {
        case PROVINCESS:
            return {
                ...state,
                provincesData: action.provincesData
            }
        case CODE_NIC:
            return {
                ...state,
                nicData: action.nicData
            }
        case CODE_NIC_OWNER:
            return {
                ...state,
                nicOwner: action.nicOwner
            }
        case GET_CODE_REGION:
            return {
                ...state,
                regionData: action.regionData
            }
        case CHECK_IMG:
            return {
                ...state,
                imgList: {...state.imgList, ...action.imgList}
            }
        case GET_REGION_DATA_ENTRIES:
            return {
                ...state,
                regionDataEntries: action.regionDataEntries
            }    
        default:
            return state;
    }
}
export const getRegionDataEntries = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_REGION_DATA_ENTRIES,
            regionDataEntries: ownProps.regionDataEntries
        })
    })
}

export const getRegionProvinces = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: PROVINCESS,
            provincesData: ownProps.provincesData
        })
    })
}

export const getCodeNic = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: CODE_NIC,
            nicData: ownProps.nicData
        })
    })
}

export const getCodeNicOwner = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: CODE_NIC_OWNER,
            nicOwner: ownProps.nicOwner
        })
    })
}

export const getCodeRegion = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: GET_CODE_REGION,
            regionData: ownProps.regionData
        })
    })
}



