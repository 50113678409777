export const GET_ENABLE_BACKFILL = 'enableBackfill/GET_ENABLE_BACKFILL';
export const CHANGE_INITIAL_STATE = 'enableBackfill/CHANGE_INITIAL_STATE';
export const SET_BACKFILL_KEY = 'enableBackfill/GET_BACKFILL_TYPE';
export const GET_ENABLE_BACKFILL_TYPE = 'enableBackfill/GET_ENABLE_BACKFILL_TYPE';
export const CHANGE_OMIT_BACK_FILL = 'enableBackfill/CHANGE_OMIT_BACK_FILL';
export const IS_DETAILS_BAACKFILL= 'enableBackfill/IS_DETAILS_BAACKFILL';

const initialState = {
    backfillValues: {},
    key: '',
    type: '',
    details: [] // 是否企业项目回填
}

export default (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case GET_ENABLE_BACKFILL:
            return {
                ...state,
                backfillValues: {
                    ...state.backfillValues,
                    [action.key]: action.scrollTop
                }
            }
        case CHANGE_OMIT_BACK_FILL:
            return {
                ...state,
                backfillValues: action.backfillValues
            }
        case SET_BACKFILL_KEY:
            return {
                ...state,
                key: action.key
            }
        case GET_ENABLE_BACKFILL_TYPE:
            return {
                ...state,
                type: action.value
            }
        case IS_DETAILS_BAACKFILL:
            return {
                ...state,
                details: action.details
            }
        case CHANGE_INITIAL_STATE:
            return initialState
        default:
            return state;
    }
    
}

export const changeDetailsBackfill = (ownProps) => {
    return {
        type: IS_DETAILS_BAACKFILL,
        details: ownProps.details
    }
}

export const changeOmitBackfillValues = (ownProps) => {
    return {
        type: CHANGE_OMIT_BACK_FILL,
        backfillValues: ownProps.backfillValues
    }

}

export const initialStateBackfill = () => {
    return {
        type: CHANGE_INITIAL_STATE
    }
}

export const getEnableBackfillType = (ownProps) => {
    return {
        type: GET_ENABLE_BACKFILL_TYPE,
        value: ownProps.type
    }
}

export const getEnableBackfillKey = (ownProps) => {
    return {
        type: SET_BACKFILL_KEY,
        key: ownProps.key
    }
}

export const getEnableBackfillState = (ownProps) => {
    return {
        type: GET_ENABLE_BACKFILL,
        key: ownProps.key,
        scrollTop: ownProps.scrollTop
    }
}

