export const CHANGE_IDENTITY = 'identity/CHANGE_IDENTITY';

const initialState = {
    identityValue: ''
}

export default (state, action) => {
    state = state || initialState;
    if (action.type === CHANGE_IDENTITY) {
        return {
            ...state,
            identityValue: action.identityValue
        }
    } else {
        return state;
    }
}

export const changeIdentityMap = (ownProps) => {
    return {
        type: CHANGE_IDENTITY,
        identityValue: ownProps.identityValue
    }

}