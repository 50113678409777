export const workbench_materials = 'workbench';
export const subscribeCenter_materials = 'subscribeCenter';
export const monitorCenter_materials = 'monitor';
export const search_materials = 'search';

export const workbench_company_materials = 'workbench_company_materials';
export const workbench_product_materials = 'workbench_product_materials';

// 后台data下项目   api 不需要加bid
export const dataApi = [
  '/data/details/rec-industry',
  '/data/details/rec-owner',
  '/data/details/tags',
  '/data/details/business-tags',
]
