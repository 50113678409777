export const TABS_ENTERPRISE = 'enterprise/TABS_ENTERPRISE';
export const INITAL_STATE = 'enterprise/INITAL_STATE';

const initialState = {
    tab: 0
}

export default (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case TABS_ENTERPRISE:
            return {
                ...state,
                tab: action.tab
            }
        case INITAL_STATE:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
}

export const changeEnterpriseTabs = (ownProps) => {
    return {
        type: TABS_ENTERPRISE,
        tab: ownProps.tab
    }

}