import _ from 'lodash'
import moment from 'moment'

export const getMomentFormat = (value) => {
    return moment(value).format('YYYY-MM-DD')
}

// 信息类型配置
export const getSearchTypeConfig = (searchType1 = [], searchType2 = [], isAll = false) => {
    const params = [
        {
            label: "招标",
            value: "1",
            children: [
                ...searchType1
              ],
        }, {
            label: "中标",
            value: "2",
            children: [
                ...searchType2
            ],
        }
    ]
    if(isAll) {
        params.push({
            label: "拟在建",
            value: "50",
            children: [
              {
                label: '拟在建',
                value: '50'
              }
            ] 
        })
    }
    return params
}

export const getTypeValues = (data = []) => {
    const list = data.reduce((memo, item) => {
        const children = item.children.map(subItem => subItem.value);
        memo = {...memo, [item.value]: children}
        return memo
    }, {})
    return list
}

const getCodes = (data) => {
    const oneLeveData = data.filter((item) => item.code !== '0').filter(itemLevel => +itemLevel.level === 1).map((suItem) => suItem.code)
    return oneLeveData
}
// 获取一级code
export const getOneLeveRegionData = (data, type) => {
   const oneLeveDataLocal = localStorage.getItem(config[type])
   if(oneLeveDataLocal) {
       const _oneLeveDataLocal = JSON.parse(oneLeveDataLocal)
       if( _oneLeveDataLocal && _oneLeveDataLocal.length) {
        return _oneLeveDataLocal
       }
   }
   const oneLeveData = getCodes(data)
   localStorage.setItem(config[type],  JSON.stringify(oneLeveData))
   return oneLeveData
}

const config = {
    'region': 'oneLeveRegionData',
    'industries': 'oneLeveIndustryData',
    'nic': 'oneLevenicData'
  
}
// 校验是否全选一级code
export const changeIsAllRegionCode = (codes, data, type) => {
    const oneLeveCode = getOneLeveRegionData(data, type)
    return _.isEqual(oneLeveCode, _.sortBy(codes))
}

