export const GET_ENTERPRISES_BASE = 'enterprisesBase/GET_EENTERPRISES_BASE';
export const CHANGE_HAS_COLLECTES = 'enterprisesBase/CHANGE_HAS_COLLECTES'

const initialState = {
    baseData: {},
    isHasCollected: false
}

export default (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case GET_ENTERPRISES_BASE:
            return {
                ...state,
                 baseData: action.baseData
            }
        case CHANGE_HAS_COLLECTES:
            return {
                ...state,
                isHasCollected: action.isHasCollected
            }
        default:
            return state;
    }
    
}

export const changeHasCollected = (ownProps) => {
    return {
        type: CHANGE_HAS_COLLECTES,
        isHasCollected: ownProps.isHasCollected
    }
}

export const getEnterprisesBase = (ownProps) => {
    return {
        type: GET_ENTERPRISES_BASE,
        baseData: ownProps.baseData
    }

}