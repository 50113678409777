import {listType} from '@/routes/Enterprise/config';

export const CHANGE_ENTERPRISE_LOADING = 'enterprise/CHANGE_ENTERPRISE_LOADING';
export const GET_ENTERPRISE_TOTAL = 'enterprise/GET_ENTERPRISE_TOTAL';
export const GET_ENTERPRISE_PAGE = 'enterprise/GET_ENTERPRISE_PAGE';
export const CHANGE_INITA_STATE = 'enterprise/CHANGE_INITA_STATE';
export const FETCH_ENTERPRISE_DETAILS_LIST = 'enterprise/FETCH_ENTERPRISE_DETAILS_LIST';

const initialState = {
    loading: false,
    [listType.INFORMATION.value]: {
        list: [],
        page: 1,
        total: 0
    },
    [listType.COOPERATION.value]: {
        list: [],
        page: 1,
        total: 0
    },
    [listType.DETAILS.value]: {
        list: [],
        page: 1,
        total: 0
    },
    [listType.TA.value]: {
        list: [],
        page: 1,
        total: 0
    }
}


export default (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CHANGE_ENTERPRISE_LOADING:
            return {
                ...state,  
                loading: action.loading
            }
        case GET_ENTERPRISE_TOTAL:
            return {
                ...state,  
                [action.key]: {
                    ...state[action.key],
                    total: action.total
                }
            }
        case GET_ENTERPRISE_PAGE:
            return {
                ...state,  
                [action.key]: {
                    ...state[action.key],
                    page: action.page
                }
            }
        case FETCH_ENTERPRISE_DETAILS_LIST: {
            return {
                ...state, 
                [action.key]: {
                    ...state[action.key],
                    list: action.list
                }
            }
        }
        case CHANGE_INITA_STATE:
            return initialState
        default:
            return state;
    }
}

export const fetchEnterpriseList = (ownProps) => {
    return {
        type: FETCH_ENTERPRISE_DETAILS_LIST,
        list: ownProps.data,
        key: ownProps.type
    }

}

export const changeIEnterprisenitaState = () => {
    return {
        type: CHANGE_INITA_STATE,
    }
}

export const getEnterprisePage = (ownProps) => {
    return {
        type: GET_ENTERPRISE_PAGE,
        page: ownProps.page,
        key: ownProps.type
    }
}

export const getEnterpriseTotal = (ownProps) => {
    return {
        type: GET_ENTERPRISE_TOTAL,
        total: ownProps.total,
        key: ownProps.type
    }
}

export const changeEnterpriseLoading =  (ownProps) => {
    return {
        type: CHANGE_ENTERPRISE_LOADING,
        loading: ownProps.loading
    }
}