/**
 * @file 标讯-业主-地域选择
 * @author wushaobo
 */
 import {mapValues} from 'lodash';
 import {combineReducers} from 'redux';
 import {combineActionTypes} from '@/routes/common/actionTypes';
 import saveCode, {actionTypesFactory as saveCodeActionTypesFactory} from '@/modules/reduces/selectLevel/saveCode';
 

 const PREFIX = 'TA_REGION';


 const actionTypesFactory = combineActionTypes(
    saveCodeActionTypesFactory
 );

 const reducersFactory = (prefix) => combineReducers(
    mapValues(
        {
            saveCode
        },
        reducerFactory => reducerFactory(prefix)
    )  
);

export const ACTIONS = actionTypesFactory(PREFIX);
export default reducersFactory(PREFIX);