export const HISTORY_CENTER_TAB = 'historyCenter/HISTORY_CENTER_TAB';

const initialState = {
    tab: 0
}

export default (state = initialState, action = {}) => {
    if (action.type === HISTORY_CENTER_TAB) {
        return {
            ...state,
            tab: action.tab
        }
    }
    return state;
}

// 监控企业id
export const getHistoryCenterTab = ownProps => dispatch => {
    new Promise(resolve => {
        dispatch({
            type: HISTORY_CENTER_TAB,
            tab: ownProps.tab
        })
    })
}