/**
 * @file 标讯-业主-信息类型
 * @author wushaobo
 */
 import {mapValues} from 'lodash';
 import {combineReducers} from 'redux';
 import {combineActionTypes} from '@/routes/common/actionTypes';
 import saveCode, {actionTypesFactory as informationActionTypesFactory} from '@/modules/reduces/selectLevel/saveCode';

 const PREFIX = 'DETAILS_INFORMATION';

 const actionTypesFactory = combineActionTypes(
    informationActionTypesFactory
 );

 const reducersFactory = (prefix) => combineReducers(
    mapValues(
        {
            saveCode
        },
        reducerFactory => reducerFactory(prefix)
    )
);

export const ACTIONS = actionTypesFactory(PREFIX);
export default reducersFactory(PREFIX);