import { combineReducers } from 'redux';

import auth from './auth';
import layout from './layout';
import subscribeCenter from './subscribeCenter';
import login from './login';
import provinces from './provinces';
import monitor from './monitor';
import searchData from './searchData';
import historyCenter from './historyCenter';
import tabs from './tabs';
import workbench from './workbench';
import search from './search';
import projectInfoType from './projectInfoType';
import seniorRegion from './seniorRegion';
import newSubscribe from './newSubscribe';
import common from './common';
import enterprise from './enterprise';
import groupPurchase from './groupPurchase';


export default combineReducers({
  layout,
  auth,
  subscribeCenter,
  login,
  provinces,
  monitor,
  searchData,
  historyCenter,
  tabs,
  workbench,
  search,
  projectInfoType,
  seniorRegion,
  newSubscribe,
  common,
  enterprise,
  groupPurchase
});