/**
 * @file connect
 * @author wushaobo
 * @date 2021/11/10
 */

 import {mapValues} from 'lodash';
 import {connect} from 'react-redux';
 
 export const target = targetName => handler => {
     return {
         targetName,
         handler
     };
 };
 
 export default (mapStateToProps, mapDispatchToProps) => WrappedComponent => {
     const dispatchToProps = (dispatch, ownProps) =>
     mapValues(mapDispatchToProps, ({targetName, handler}) => (...args) => {
         let actionCreator = handler(ownProps, ...args);
         // 封装dispatch
         return dispatch((_dispatch, getState) => {
             return actionCreator(_dispatch, getState);
         });
     })
     return connect(mapStateToProps, dispatchToProps)(WrappedComponent);
 }