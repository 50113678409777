import { combineReducers } from 'redux';

import information from './information';
import moreProjectFiltering from './moreProjectFiltering';
import time from './time';

export default combineReducers({
    information,
    moreProjectFiltering,
    time
  });