/*
 * @Description:
 * @Autor: wushaobo
 * @Date: 2022-1-19 
 */

// 筛选信息类型 
export const INFORMATION_TYPE = 'searchResult/INFORMATION_TYPE';

const initialState = {
    searchType1: [],
    searchType2: [],
    informationType: '123' // 筛选信息类型
}

export default (state = initialState, action) => {
    if (action.type === INFORMATION_TYPE) {
        return {
            ...state,
            informationType: action.informationType
        }
    }
    return state;
}


export const setInformationType = ownProps => {
    return {
        type:  INFORMATION_TYPE,
        informationType: ownProps.informationType
    }

}