import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import Routes, { ExternalRoutes } from "./routes";
import { toggleSiderMenu } from "./modules/layout";
import { setResources } from '@/modules/login'
import './routes/utils';
import './app.less';
import { getQueryVariable, isChsme, isOem, systemUtils } from "utils/utils";
import Tab from '@/components/tab';
import axios from "axios";
import {getRegionState} from '@/components/region/util'
import { getCodeRegion, getRegionProvinces, getCodeNic, getCodeNicOwner } from '@/modules/provinces';
import {getInformationData} from '@/utils/information';
import {
  getProvincesUtil,
  getParentUtils,
  getOwnerUtils
} from '@/routes/SubscribeCenter/utils';
import {getDetailsBackfillGrey} from '@/routes/Enterprise/util'
import { IconFont } from '@/utils/utils'
if (typeof window !== 'undefined' 
  && (['m.qibiaoduo.com', 'm-pre.qibiaoduo.com'].indexOf(window.location.host) === -1 || getQueryVariable('vconsole') === '1')
) {
  // import (/* webpackChunkName: 'vconsole-dev' */ 'vconsole')
  // .then(({default: VConsole}) => {
  //    new VConsole();
  // })
}

const { getPath } = systemUtils;
let isWake = false;

const App = (props) => {
  const {
    history,
    resources,
    getRegionProvinces: _getRegionProvinces,
    provincesData,
    getCodeNic: _getCodeNic,
    getCodeNicOwner: _getCodeNicOwner,
    nicData,
    nicOwner,
    getInformationData: _getInformationData,
    getRegionState: _getRegionState,
    getDetailsBackfillGrey: _getDetailsBackfillGrey
  } = props;

useEffect(() => {
  // 兼容app, 不需要请求
  if(location.pathname.indexOf('AppAnnounce') > -1 || location.pathname.indexOf('/AMap') > -1 ||location.pathname.indexOf('loingbychsme')>-1) return
  _getInformationData()
  _getRegionState()
  !nicData.length && getParentUtils(_getCodeNic)
  !nicOwner.length && getOwnerUtils(_getCodeNicOwner)
  !provincesData.length && getProvincesUtil(_getRegionProvinces)
  _getDetailsBackfillGrey()

}, [])

  useEffect(() => {
    if (resources?.length > 0 && !isWake) {
        // 埋点：页面唤醒pv
        isWake = true;
        axios.put('/account/log/wake-record').then(res => {
        })
    }
  }, [resources]);
  return <div className="app">
    {[
      '/inputCompanyName','/register','/SpecialArea','/Application/Recommend', '/login', '/user/official', '/SelectLocation', '/HistoryCenter', '/CollectionCenter',
      '/MonitorCenter/company', '/MonitorCenter',
      '/MonitorCenter/addCompany', '/SubscribeCenter/SubscribeManage',
      '/SubscribeCenter/newSubscribe', '/ProjectDetails', '/oldEnterpriseDetails', '/oldEnterpriseDetails/ProjectLists',
      '/UserCenter/PrivilegeDeatils', '/AMap', '/NoAccessView',
      '/search', '/IframePage', '/AppAnnounce', 
      '/Enterprise/EnterpriseDetails', '/Enterprise/EnterpriseList', '/Enterprise/ProjectList', '/Enterprise/EnterContactList',
      '/GroupPurchase','/user/loginbychsme','/user/loginbyoem'
    ].map(item =>
      getPath(item)).includes(history.location.pathname.replace(/\/$/, ''))
      ? <div className="app-conent-box">
         {isOem()&&<div style={{paddingTop:'40px'}}> <div style={{textAlign:'center',fontSize:'0.3rem',marginBottom:'10px'}}>
          <IconFont onClick={()=>window.history.back()} style={{transform:'rotate(180deg)',position:'absolute',left:'4px'}} type='icon-project_arrow' /> 招标采购</div></div>}
           <div className="routes" id="routes" ><ExternalRoutes />  </div>
      </div>
      : <div className="app__layout-main">
        <div className="routes" id="routes" ><Routes /></div>
        {!isChsme()&&<Tab history={history} />}
        
      </div>
    }
  </div>
}

const mapStateToProps = state => ({
  resources: state.login.resources,
  isCollapsed: state.layout.isCollapsed,
  regionData: state.provinces.regionData,
  provincesData: state.provinces.provincesData,
  nicData: state.provinces.nicData,
  nicOwner: state.provinces.nicOwner,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleSiderMenu, setResources, getCodeRegion, getRegionProvinces, getCodeNic, getCodeNicOwner, getInformationData, getRegionState, getDetailsBackfillGrey }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
