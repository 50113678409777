export const tabtype = 'search/TABTYPE';

export default (state = {}, action) => {
    if (action.type === tabtype) {
        return {
            ...state,
            project: action.project,
            enterprises: action.enterprise,
            Tabtype: action.Tabtype
        }
    }
    return state;
}

export const saveSearchData = (project, enterprise, Tabtype) => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: tabtype,
            project,
            enterprise,
            Tabtype,
        })
    })
