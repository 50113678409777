import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { Modal} from 'antd';
import rootReducer from './modules';

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' && window.document
);

export default (url = '/') => {
  // Create a history depending on the environment
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
        getUserConfirmation(message, callback) {
          Modal.confirm({
            title:message,
            onCancel: () => {
              callback(false);
            },
            onOk: () => {
              callback(true);
            }
          })
        }
      })
    : createBrowserHistory({
      getUserConfirmation(message, callback) {
        Modal.confirm({
          title:message,
          onCancel: () => {
            callback(false);
          },
          onOk: () => {
            callback(true);
          }
        })
      }
    });

  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    // Note that starting from v2.7, window.devToolsExtension was renamed to 
    // window.__REDUX_DEVTOOLS_EXTENSION__ / window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__.
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [thunk, routerMiddleware(history)];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  // Create the store
  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return {
    store,
    history
  };
};
