import { combineReducers } from 'redux';
import message from './message';
import cooperation from './cooperation';
import details from './details';
import ta from './ta';

export default combineReducers({
    message,
    cooperation,
    details,
    ta
})