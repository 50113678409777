import App from './app';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Frontload } from "react-frontload";
import { ConnectedRouter } from "connected-react-router";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

import createStore from "./store";
// Create a store and get back itself and its history object
const { store, history } = createStore();

const renderMethods = module?.hot ? ReactDOM.render : ReactDOM.hydrate;
renderMethods(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Frontload noServerRender={true}>
        <ConfigProvider locale={zhCN}>
          <App />
        </ConfigProvider>
      </Frontload>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
