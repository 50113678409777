/*
 * @Description: 公用state
 * @Autor: 吴少波
 * @Date: 2022-04-19
 */

export const APP_INFORMATION= 'app/APP_INFORMATION';

const initialState = {
    infoType: []
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case APP_INFORMATION:
            return {
                ...state,
                infoType: action.infoType
            }
        default:
            return state;
    }
}

export const getInfoType = (ownProps: any) => {
    return {
        type: APP_INFORMATION,
        infoType: ownProps.infoType
    }
}
