/*
 * @Description:
 * @Autor: wushaobo
 * @Date: 2022-03-11
 */
export const GET_SUB_SCRIBE_MANAGE = 'newSubscribe/GET_SUB_SCRIBE_MANAGE';
export const GET_MAONITOR_CENTER = 'newSubscribe/GET_MAONITOR_CENTER';

const initialState = {
    subData: {}
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SUB_SCRIBE_MANAGE:
            return {
                ...state,
                subData: action.subData
            }
        case GET_MAONITOR_CENTER:
            return {
                ...state,
                monitorData: action.monitorData
            }
        default:
            return state;
    }
}

export const getSubData = (ownProps: any) => {
    return {
        type: GET_SUB_SCRIBE_MANAGE,
        subData: ownProps.subData
    }
}
