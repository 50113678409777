import {listType} from '@/routes/Enterprise/config';

export const GET_REGIEON_DATA = 'enterprise/GET_REGIEON_DATA';
export const GET_INFO_TYPE_DATA = 'enterprise/GET_INFO_TYPE_DATA';
export const GET_OPT_REGIEON_DATA = 'enterprise/GET_OPT_REGIEON_DATA';
export const GET_OPT_INFO_TYPE_DATA = 'enterprise/GET_OPT_INFO_TYPE_DATA'
export const SEARCH_BAR = 'enterprise/SEARCH_BAR';
export const SET_SEARCH_BAR = 'enterprise/SET_SEARCH_BAR';
export const CHANGE_DEFINE_VALUES = 'enterprise/CHANGE_DEFINE_VALUES';



const initialState = {
    isSearch: false, // 是否展示搜索框
    searchValues: '', // input输入内容
    searchDefineValues: '', // 确定后的值
    [listType.INFORMATION.value]: {
        regionData: [], // 地域数据
        infoTypeData: [],// 信息数据
        optRegionData: [], // 筛选后地域数据
        optInfoTypeData: [], // 筛选后信息数据
        isSearch: false, // 是否展示搜索框
        searchValues: '', // input输入内容
        searchDefineValues: '', // 确定后的值
    },
    [listType.COOPERATION.value]: {
        regionData: [],
        infoTypeData: [],
        optRegionData: [], 
        optInfoTypeData: [], 
        isSearch: false, 
        searchValues: '', 
        searchDefineValues: ''
    },
    [listType.DETAILS.value]: {
        regionData: [],
        infoTypeData: [],
        optRegionData: [],
        optInfoTypeData: [], 
        isSearch: false, 
        searchValues: '', 
        searchDefineValues: ''
    },
    [listType.TA.value]: {
        regionData: [],
        infoTypeData: [],
        optRegionData: [],
        optInfoTypeData: [],
        isSearch: false, 
        searchValues: '', 
        searchDefineValues: ''
    }
}

export default (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CHANGE_DEFINE_VALUES:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchDefineValues: action.searchDefineValues
                }
            }
        case SET_SEARCH_BAR:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    searchValues: action.searchValues
                }
            }
        case GET_REGIEON_DATA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    regionData: action.regionData
                }
            
            }
        case GET_INFO_TYPE_DATA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    infoTypeData: action.infoTypeData
                }
            }
        case GET_OPT_REGIEON_DATA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    optRegionData: action.optRegionData
                }
            }
        case GET_OPT_INFO_TYPE_DATA:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    optInfoTypeData: action.optInfoTypeData
                }
            }
        case SEARCH_BAR:
                return {
                    ...state,
                [action.key]: {
                    ...state[action.key],
                    isSearch: action.isSearch
                }
            }
        default:
            return state;
    }
}

export const changeSearchDefineValues = (ownProps) => {
    return {
        type: CHANGE_DEFINE_VALUES,
        searchDefineValues: ownProps.searchDefineValues,
        key: ownProps.type
    }
}

export const getEnterpriseRegionData = (ownProps) => {
    return {
        type: GET_REGIEON_DATA,
        regionData: ownProps.regionData,
        key: ownProps.type
    }
}

export const getEnterpriseInfoTypeData = (ownProps) => {
    return {
        type: GET_INFO_TYPE_DATA,
        infoTypeData: ownProps.infoTypeData,
        key: ownProps.type
    }
}

export const getEnterpriseOptRegionData = (ownProps) => {
    return {
        type: GET_OPT_REGIEON_DATA,
        optRegionData: ownProps.optRegionData,
        key: ownProps.type
    }
}

export const getEnterpriseOptInfoTypeData = (ownProps) => {
    return {
        type: GET_OPT_INFO_TYPE_DATA,
        optInfoTypeData: ownProps.optInfoTypeData,
        key: ownProps.type
    }
}

export const setIsSearch = (ownProps) => {
    return {
        type: SEARCH_BAR,
        isSearch: ownProps.isSearch,
        key: ownProps.type
    }
}

export const setSearchBar = (ownProps) => {
    return {
        type: SET_SEARCH_BAR,
        searchValues: ownProps.searchValues,
        key: ownProps.type
    }
}

